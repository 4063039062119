body,
html {
  font-family: $sans;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  &.is-fixed {
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  &.is-fixed-nav {
    height: 100%;
    overflow: hidden;
  }

  &.has-modal {
    height: 100%;
    overflow: hidden;
  }
}

a {
  cursor: pointer;
}

body {
  background: var(--bg);
  color: var(--text-primary);
  position: relative;
}

h1.c-section__title {
  @include font-size(44px);
}

h2.c-section__title {
  @include font-size(36px);
}

h3.c-section__title {
  @include font-size(28px);
}

h4.c-section__title {
  @include font-size(20px);
}

h5.c-section__title {
  @include font-size(16px);
}

h6.c-section__title {
  @include font-size(14px);
}

header.page-header {
  align-items: center;
  display: flex;
  padding: 25px 15px;

  h3 {
    margin: 0;
  }

  button {
    align-self: center;
    margin-left: auto;
  }

  h2 {
    margin: 0 auto 0 0;
  }

  @include mq($until: tablet) {
    flex-wrap: wrap;
    justify-content: center;

    h2 {
      text-align: center;
      width: 100%;
    }

    .see-all {
      margin: 10px 0;
    }

    label {
      display: none;
    }
  }
}

.section-header {
  color: var(--text-primary);
  font-family: $spectral;
  font-weight: 300;
  padding: 15px 30px;

  &.previous {
    padding: 15px 10px;
  }

  @include font-size(28px);
}

main {
  background-color: $white;
  min-height: 100vh;
  padding-top: 70px;
  position: relative;

  &#itemDetailsPage {
    padding-top: 130px;
  }

  &#loginPage {
    padding: 0;
  }

  &#categoriesPage,
  &#favoritesPage,
  &#locationsPage,
  &#merchantsPage,
  &#latestPurchasesPage,
  &#collectionsPage {
    background-color: $gold8;
    padding-bottom: 50px;
  }

  &#artInventoryPage {
    background-color: $white;
    padding-top: 124px;

    @include mq($until: tablet) {
      padding-top: 130px;
    }
  }

  &#artInventoryPage {
    background-color: $white;
  }

  .slick-container {
    padding: 0 8px;
  }
}

// login
.c-section--login {
  align-items: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0;

  .o-section__wrapper {
    padding: 0;

    @include mq(tablet) {
      padding: 0 1em;
    }
  }
}

// login
video#bgvid {
  animation: fadeInBgVideo 1s;
  background-size: cover;
  bottom: 0;
  height: 100vh;
  left: 0;
  object-fit: cover;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1;
}

.o-section__wrapper.login-wrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.c-login {
  align-items: center;
  background-color: var(--bg);
  border-radius: 2px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  max-width: calc(100% - 20px);
  min-height: 480px;
  padding: 40px 5vw 50px;
  position: relative;
  width: 682px;
  z-index: 2;

  h1 {
    margin-bottom: auto;
  }

  form {
    max-width: 100%;
    width: 300px;

    input {
      max-width: 100%;
    }
  }

  .loading-tables {
    height: 24px;
    margin-bottom: 25px;
    text-align: center;

    .block-wrap {
      display: flex;
      justify-content: center;

      .loading-block {
        background-color: $forest;
        height: 10px;
        margin: 0 5px;
        width: 10px;

        span {
          display: none;
        }
      }
    }
  }

  .c-login__logo {
    margin-bottom: 1em;
    margin-top: auto;
  }

  .c-login__title {
    color: var(--primary-text);
    font-family: $spectral;
    font-weight: 300;
    margin: 0 0 20px;
    @include font-size(30px);

    @include mq(tablet) {
      @include font-size(32px);
    }
  }
}

main.home {
  overflow: hidden;
}

.btn-home {
  background-color: #7a673c;
  color: #ffffff;
  display: block;
  margin: 10px;
  padding: 50px 30px;
  text-decoration: none !important;
  text-transform: uppercase;
  transition: all 0.2s;

  &:hover {
    background-color: #000000;
    transition: all 0.2s;
  }
}

*:focus,
*:active {
  outline: none !important;
}

@-webkit-keyframes fadeInBgVideo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInBgVideo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInBgVideo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInBgVideo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// locations
.c-section--location,
.c-section--locations {
  padding-top: 0;
}

.c-locations__list {
  justify-content: flex-start;
  margin: 0;
  max-width: 100%;

  .o-list__items {
    margin: 0 0.5em 1em;

    @include mq(tablet) {
      margin: 0 0.5em 1em;
    }
  }
}

.c-locations__btn {
  text-align: center;
}

.content-block {
  color: $dark;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 15px;
  width: 1200px;
}

// old location detail page
main.location-detail {
  font-weight: 300 !important;

  .location {
    align-items: center;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    min-height: 100vh;

    .hero-wrap {
      border-bottom: 1px solid $black;
      min-height: 80vh;
      position: relative;
      width: 100%;

      .hero-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;
      }
    }

    h2 {
      color: $dark;
      font-size: 60px;
    }

    .location-details {
      h4 {
        margin: 10px 0;
      }

      .detail {
        align-items: flex-start;
        border-bottom: 1px solid $light-gray;
        display: flex;
        overflow: hidden;
        padding: 10px 0;

        label {
          font-weight: bold;
          min-width: 200px;

          i {
            font-size: 20px;
            margin-right: 8px;
          }
        }

        .location-image {
          margin-left: 30px;

          img {
            max-width: 100%;
          }
        }

        ul {
          margin: 0;
          min-width: fit-content;
          padding: 0 0 0 20px;

          &.photos {
            display: flex;
            list-style: none;
            padding: 0;

            li {
              overflow: hidden;
              width: 0;

              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                width: calc((100% - 200px) / 3);
              }

              &:nth-child(2) {
                margin: 0 15px;
              }

              img {
                width: 100%;
              }
            }

            @include mq($until: tablet) {
              li {
                overflow: hidden;
                width: 0;

                &:nth-child(1),
                &:nth-child(2) {
                  min-width: 50%;
                  width: 50%;

                  img {
                    width: 100%;
                  }

                  &:nth-child(2) {
                    margin: 0 0 0 15px;
                  }
                }
              }
            }

            @include mq($until: mobileLandscape) {
              li {
                overflow: hidden;
                width: 0;

                &:first-child {
                  margin: 0;
                  min-width: 100%;
                  width: 100%;

                  img {
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        @include mq($until: tablet) {
          &.gallery {
            flex-direction: column;
            flex-wrap: wrap;

            label {
              margin-bottom: 10px;
              width: 100%;
            }
          }
        }

        @include mq($until: mobileLandscape) {
          flex-direction: column;
          flex-wrap: wrap;

          label {
            margin-bottom: 10px;
            width: 100%;
          }
        }
      }
    }

    .item-detail-wrap.notes {
      margin-top: auto;
    }

    .btn-toolbar {
      margin-top: auto;
    }

    .btn-toolbar {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 15px 0;
      width: 100%;

      span {
        margin: 0 15px 0 0;
      }

      a {
        margin: 0;
      }

      @media screen and (max-width: 560px) {
        justify-content: center;

        span {
          margin-right: 0;
          text-align: center;
          width: 100%;
        }

        a {
          margin-top: 15px;
          text-align: center;
          width: 100%;
        }
      }
    }

    @include mq($until: tablet) {
      .content-block {
        padding: 30px 20px;
      }
    }

    @include mq($until: mobileLandscape) {
      .content-block {
        padding: 30px 10px;
      }
    }
  }

  @include font-size(16px);
}

// categories
.c-section--categories {
  padding-top: 0;
}

.c-categories__list {
  justify-content: flex-start;
  margin: 0;

  .o-list__items {
    margin: 0 0.5em 1em;

    @include mq(tablet) {
      margin: 0 0.5em 1em;
    }
  }
}
