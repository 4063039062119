/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
@use "sass:math";

html {
  font-size: math.div($inuit-global-font-size, 16px) * 1em;
  line-height: math.div($inuit-global-line-height, $inuit-global-font-size);
  overflow-y: scroll;
  min-height: 100%;
}
