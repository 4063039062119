.c-room {
  padding: 0 0 10px;

  header {
    display: none;
    justify-content: space-between;
    padding: 0 1em;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    @include mq(tablet) {
      display: flex;
    }

    span {
      @include font-size(20px);
      font-weight: 300;
      font-family: $spectral;
      color: var(--text-primary);

      &.item {
        width: 80px;
        text-align: center;
        @include mq(tablet) {
          width: 100px;
        }
      }

      &.destination {
        margin-left: auto;
        margin-right: 40px;
      }

      &.remove {
        margin-right: 10px;
      }
    }
  }

  footer {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
      white-space: nowrap;
      width: auto;
    }

    @include mq($until: tablet) {
      padding: 15px;
      flex-direction: column;
      flex-wrap: wrap;
      button {
        width: 100%;
        margin: 0 0 15px;
        max-width: 100%;
      }
    }
  }

  @include mq($until: tablet) {
    .c-room__details {
      width: 100%;
    }
  }
}

.c-btn--room {
  &:disabled {
    background-color: var(--btn-disabled);
    cursor: not-allowed;
  }
}

.c-dropdown__locations {
  margin-right: 1em;

  &.is-disabled {
    .c-dropdown__title {
      color: var(--btn-disabled);
      cursor: not-allowed;
    }
  }

  ul.c-dropdown__list {
    right: 0;
    min-width: 230px;
    width: fit-content;

    li.c-dropdown__list__items {
      a.c-link--dropdown {
        white-space: nowrap;
      }
    }
  }
}

.locations-dropdown {
  font-family: $sans;
  position: relative;
  margin-left: auto;
  @include mq($until: tablet) {
    margin: 0;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .c-dropdown {
    width: 160px;
    position: relative;
    @include mq($until: tablet) {
      margin: 15px 0 0 !important;
    }

    .c-dropdown__title {
      span.c-dropdown__text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }

    ul.c-dropdown__list {
      right: 0;
      max-height: 300px;
      overflow-y: scroll !important;
      min-width: 240px;
      width: fit-content;
      padding-bottom: 60px;

      li.c-dropdown__list__items {
        a.c-link--dropdown {
          white-space: nowrap;
        }
      }

      &.dropup {
        bottom: 41px;
      }
    }
  }
}

.c-room__location {
  @extend .c-works__location;
  @include font-size(14px);
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 !important;
  margin: 0;
  flex-wrap: wrap;
  align-items: center;
  display: flex;

  .material-icons {
    font-size: 18px;
    color: $gold;
  }

  @include mq($until: tablet) {
    justify-content: center;
    align-self: flex-start;
    transform: translateX(-15px);
  }
}

.c-room__image {
  width: 100px;
  @include mq($until: tablet) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.c-room__img {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  @include mq(tablet) {
    width: 100px;
    height: 100px;
  }
}

.c-room__list__items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em;
  border-bottom: 1px solid var(--border-color);
  position: relative;

  &:nth-child(2n) {
    background-color: var(--bg-secondary);
  }

  &:hover {
    .c-room__action {
      opacity: 1;
      margin-left: auto;
    }
  }

  @include mq($until: tablet) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .c-room__details__title {
      margin: 10px auto;
    }
    .scan-status {
      margin: 10px auto 5px;
    }
  }
}

.c-room__details {
  margin-left: 1em;
  padding-right: 30px;
  @include mq($until: tablet) {
    padding: 0;
    margin: 0;
    text-align: center;
  }
}

.c-room__details__title {
  @include font-size(23px);
  font-weight: normal;
  font-family: $spectral;
  margin: 0;
  max-width: 90%;
  @include mq($until: tablet) {
    @include font-size(18px);
    margin: 10px 0;
    text-align: center;
  }
}

.scan-status {
  padding: 0.3em 0.9em;
  margin-left: auto;
  min-width: 120px;
  color: $white;
  font-size: 13px;
  text-align: center;
  background-color: $red;
  text-transform: uppercase;

  &.scanned {
    background-color: $success;
  }
}

.c-room__action {
  margin-left: 15px;
  opacity: 1;
  @include mq(tablet) {
    opacity: 0;
  }
  @include mq($until: tablet) {
    margin: 15px 0 0 0;
  }
}

.c-room__sendto {
  display: flex;
  align-items: center;
}

.c-room__sendto__col {
  &:first-child {
    @include font-size(17px);
    margin-right: 0.5em;
    font-weight: 500;
    color: var(--text-primary);
    display: none;
    @include mq(desktop) {
      display: flex;
    }
  }
}

::placeholder {
  color: var(--text-primary);
}

section.previous-scans {
  padding-bottom: 150px;

  header.section-header {
    @include mq($until: tablet) {
      border-bottom: 1px solid var(--border-color);
      background-color: $forest;
      color: $white;
    }
  }

  .previous-room-header {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    font-family: $spectral;
    font-size: 20px;
    margin-top: 15px;
    width: 100%;
    padding: 5px 16px;

    > div {
      width: 25%;

      &.status {
        margin-left: auto;
        width: 120px;
        text-align: center;
      }
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  .room-wrap {
    width: 100%;
    cursor: pointer;

    .room-details {
      width: 100%;
      display: flex;

      > span {
        display: none;
      }

      > div {
        display: flex;
        width: 25%;

        &.scan-status {
          width: 120px;
          justify-content: center;
          pointer-events: all;
          align-self: flex-start;
        }
      }
    }

    @include mq($until: tablet) {
      .room-details {
        flex-direction: column;
        width: 100%;
        align-items: center;

        > span {
          display: block;
          font-weight: bold;
        }

        > div {
          width: 100%;
          margin-bottom: 10px;
          text-align: center;
          justify-content: center;
        }

        .room {
        }

        .created {
        }

        .updated {
        }

        .scan-status {
          margin: 10px auto;
        }
      }
    }
  }
}
