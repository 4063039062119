.c-section--requests {
  padding: 4em 0;
  min-height: 100vh;
}

.c-requests__title {
  @include font-size(40px);
  font-weight: 300;
  font-family: $spectral;
  margin: 0 0 0.75em;
  color: var(--text-primary);
}

.c-requests__status {
  padding: 0.5em 0.6418em;
  color: white !important;
  border-radius: 2px;
  @include font-size(14px);
  font-weight: 500;
  text-transform: capitalize;
}

.c-requests__status--cancelled {
  background: var(--status-cancelled);
}

.c-requests__status--received {
  background: var(--status-received);
}

.c-requests__status--processed {
  background: var(--status-processed);
}

.c-requests__status--received {
  background: var(--status-received);
}

.c-requests__status--inprogress {
  background: var(--status-inprogress);
}

.c-requests__status--archived {
  background: var(--status-archived);
}

.c-requests__status--deleted {
  background: var(--status-deleted);
}

.c-requests__status--submitted {
  background: var(--status-submitted);
}
