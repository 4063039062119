// location detail
#app.location-page {
  background-color: $forest;
  min-height: 100vh;
  
  header.topbar {
    border-bottom: 1px solid rgba($gold, 0.5);
    @include mq($until: tabletLandscape) {
      ul.navbar.links.is-open {
        background-color: $forest !important;
        border-radius: 10px;
        margin-top: 15px;
      }
    }
    
    .nav-item.dropdown {
      background-color: transparent;
    }
  }
}

/*

    SECTION


*/
section.section {
  background-color: $forest;
  color: $white;
  position: relative;
  
  header {
    align-items: center;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;
    width: 100%;
    
    .location-name {
      background: linear-gradient(130deg, #ded0bf 0%, #a38c72 80%);
      -webkit-background-clip: text;
      border-bottom: 1px solid rgba($gold, 0.5);
      border-top: 1px solid rgba($gold, 0.5);
      color: $white;
      font-family: "DM Serif Display", "Times New Roman", serif;
      font-size: 32px;
      line-height: 1em;
      margin-top: auto;
      padding: 5px 0 10px;
      -webkit-text-fill-color: transparent;
      width: 100%;
    }
    
    .room {
      color: $gold;
      font-size: 36px;
      font-weight: 300;
      line-height: 1em;
      
      &.onePic {
        margin-bottom: 55px;
      }
    }
    
    &.details {
      .location-name {
        margin-top: 130px;
      }
      
      .room {
        margin-bottom: auto;
      }
    }
    
    @include mq($until: tablet) {
      height: auto;
      .location-name {
        margin-top: 30px;
      }
    }
  }
  
  .content {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    position: relative;
    
    .container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0 auto;
      padding: 15px 0;
      width: 100%;
    }
    
    h4.royal-text {
      font-family: $dm;
      font-size: 32px;
      margin: 10px 0;
    }
    
    @include mq($from: wide) {
      .container {
        width: 1200px;
      }
    }
    @include mq($from: desktop, $until: wide) {
      .container {
        width: 980px;
      }
    }
    @include mq($until: desktop) {
      width: 100%;
      .container {
        padding: 0 15px;
      }
    }
    @include mq($until: tablet) {
      height: auto;
      .container {
        width: 100%;
      }
      .slider {
        height: 56.3vw;
        
        .slide {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          display: block;
          height: 56.3vw;
        }
      }
      .no-images-available {
        height: 56.3vw;
      }
    }
  }
  
  .no-images-available {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;
    width: 100%;
    
    h4 {
      font-family: $spectral;
      font-size: 28px;
      font-weight: 300;
      text-align: center;
    }
  }
  
  .no-data {
    color: $white;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.7em;
    margin: 0 auto 1vh;
    max-width: 900px;
    padding: 50px 15px;
    
    p {
      margin: 0;
    }
  }
  
  @include mq($until: tablet) {
    height: auto;
    .content {
      max-height: initial;
      
      header {
        padding-top: initial;
        
        .location-name {
          margin-top: initial;
        }
      }
    }
    .fp-tableCell {
      height: auto !important;
    }
  }
}

.btn-page-scroll {
  bottom: 0;
  cursor: pointer;
  left: 20px;
  position: fixed;
  width: 50px;
  z-index: 2;
  @include mq($until: tablet) {
    display: none;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
  }
}

/*

    INTRO


*/
section#introSection {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    min-width: 100%;
    position: relative;
    
    header.intro-header {
      background-image: url("/assets/images/bg_green.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      border-bottom: 5px solid rgb(195, 159, 117);
      height: 260px;
      
      .intro-title {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .cycad {
          -webkit-background-clip: text;
          background-image: linear-gradient(
              135deg,
              #c39f75 0%,
              #a47947 25%,
              #a47947 50%,
              #c39f75 75%,
              #8b673d 100%
          );
          color: $white;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          min-height: 48px;
          overflow: hidden;
          text-align: center;
          -webkit-text-fill-color: transparent;
          width: 100%;
          
          span {
            border-bottom: 1px solid $gold6;
            font-size: 42px;
            line-height: 1em;
            padding-bottom: 5px;
            @include mq($until: tablet) {
              font-size: 28px;
            }
          }
        }
        
        .location {
          -webkit-background-clip: text;
          background-image: linear-gradient(
              135deg,
              #c39f75 0%,
              #a47947 75%,
              #a47947 50%,
              #c39f75 25%,
              #8b673d 100%
          );
          color: $white;
          display: flex;
          flex-direction: column;
          font-family: $dm;
          justify-content: flex-start;
          letter-spacing: 1px;
          min-height: 90px;
          overflow: hidden;
          text-align: center;
          -webkit-text-fill-color: transparent;
          width: 100%;
          
          span {
            font-size: 72px;
            line-height: 1em;
            @include mq($until: tablet) {
              font-size: 38px;
            }
          }
        }
      }
    }
    
    .hero {
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      flex-grow: 2;
      width: 100%;
      
      &.fade-in {
        animation: fadein 2s;
      }
    }
    
    footer {
      background-image: url("/assets/images/bg_green.jpg");
      background-repeat: no-repeat !important;
      background-size: cover !important;
      border-top: 5px solid rgb(195, 159, 117);
      min-width: 100vw;
      
      .buttons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        width: 100%;
        
        .c-btn {
          margin: 5px;
        }
        
        .c-btn--primary {
          background-color: $gold;
          border-color: $gold;
          width: auto;
          
          &:hover {
            background-color: $gold-hover;
            border-color: $gold-hover;
          }
          &:active {
            background-color: $gold-active;
            border-color: $gold-active;
          }
        }
      }
      
      .back {
        margin: 40px 0 20px;
        text-align: center;
        width: 100%;
        
        a.btn-back {
          color: $white;
          display: block;
          padding: 5px 10px;
          text-decoration: none;
          transition: color 0.3s;
          
          &:hover {
            color: $gold5;
          }
        }
      }
    }
  }
}

/*

  DETAILS


*/
section#detailsSection {
  .content {
    background: rgb(13, 31, 15);
    background: linear-gradient(
        315deg,
        rgba(13, 31, 15, 1) 0%,
        rgba(43, 61, 45, 1) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    .detail-images {
      display: flex;
      margin: 1vh 0;
      opacity: 0;
      overflow: hidden;
      width: 100%;
      
      .image {
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: 200px;
        width: calc((100% / 3) - 10px);
        
        &:nth-child(2) {
          margin: 0 15px;
        }
      }
    }
    
    .description {
      color: $white;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.7em;
      margin: 0 auto 1vh;
      max-width: 900px;
      
      p {
        margin: 0;
        
        &.no-info {
          margin-top: 50px;
        }
      }
    }
    
    .details-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;
      opacity: 0;
      width: 100%;
      
      .detail {
        display: flex;
        justify-content: center;
        margin: 1vh 0;
        width: 25%;
        
        .inner {
          max-width: 100%;
          
          label {
            color: $white;
            display: block;
            font-weight: 400;
            letter-spacing: 0.05em;
            min-width: 180px;
            text-transform: uppercase;
          }
          
          .value {
            -webkit-background-clip: text;
            background-image: linear-gradient(
                135deg,
                #c39f75 0%,
                #a47947 25%,
                #a47947 50%,
                #c39f75 75%,
                #8b673d 100%
            );
            color: $white;
            font-family: "DM Serif Display", "Times New Roman", serif;
            font-size: 58px;
            font-weight: 500;
            line-height: 1.2em;
            -webkit-text-fill-color: transparent;
            
            .small {
              font-size: 22px;
              line-height: 0;
              transform: translateY(-5px);
            }
          }
        }
        
        ul {
          display: flex;
          flex-wrap: wrap;
          font-size: 16px !important;
          list-style: square;
          margin: 0;
          padding-left: 18px;
          
          li {
            margin-right: 26px;
          }
        }
      }
    }
    
    @media screen and (max-height: 1100px) {
      .detail-images {
        .image {
          height: 150px;
        }
      }
      .description {
        p {
          font-size: 15px;
          line-height: 1.5em;
        }
      }
      .details-wrap {
        .detail {
          .inner {
            .value {
              font-size: 42px;
              line-height: 42px;
            }
          }
        }
      }
    }
    @include mq($until: tablet) {
      .detail-images {
        flex-wrap: wrap;
        width: 100%;
        
        .image {
          background-position: center bottom !important;
          margin: 0 0 15px !important;
          width: 100%;
        }
      }
      .details-wrap {
        padding: 15px;
        
        .detail {
          max-width: 50%;
          min-width: fit-content;
          width: 50%;
        }
      }
    }
  }
}

/*

  GALLERY


*/
section#gallerySection {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    header {
      nav.gallery-nav {
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          list-style: none;
          margin: 0;
          padding: 0;
          
          li {
            border-bottom: 2px solid transparent;
            cursor: pointer;
            font-size: 11px;
            margin: 0 5px;
            padding: 5px 0 0;
            text-transform: uppercase;
            transition: all 0.2s;
            
            &:hover,
            &.active {
              color: $gold6;
              transition: all 0.2s;
            }
            
            @include mq($from: wide) {
              font-size: 15px;
              margin: 0 10px;
            }
          }
        }
      }
    }
    
    .gallery {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 230px);
      opacity: 0;
      width: 100%;
    }
    
    .controls {
      display: flex;
      justify-content: center;
      margin: 5px 0;
      width: 100%;
      
      > a {
        cursor: pointer;
        opacity: 1;
        
        .material-design-icon {
          color: $white;
          display: block;
          height: 20px;
          padding: 0 12px;
          transition: color 0.3s;
          
          &:hover {
            color: $gold2;
            transition: color 0.3s;
          }
          
          &.arrow-left-icon {
            border-bottom-left-radius: 18px;
            border-top-left-radius: 18px;
            text-align: left;
          }
          
          &.arrow-right-icon {
            border-bottom-right-radius: 18px;
            border-top-right-radius: 18px;
            text-align: right;
          }
          
          svg {
            height: 100%;
          }
        }
        
        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
    
    .has-one-slide {
      height: 57px;
      width: 100%;
    }
    
    .slider {
      align-self: stretch;
      border-bottom: 1px solid rgba($gold, 1);
      border-top: 1px solid rgba($gold, 1);
      display: flex;
      flex-direction: column;
      height: calc(100vh - 230px);
      
      .slide {
        background-position: center bottom !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        height: calc(100vh - 230px);
      }
      
      .caption {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.5) 100%
        );
        bottom: 0;
        font-size: 26px;
        padding: 60px 0 20px;
        position: absolute;
        text-align: center;
        width: 100vw;
      }
      
      @media screen and (max-height: 600px) {
        .slide {
          margin: 0 5px;
          width: calc(25vw - 5px) !important;
          
          .fp-tableCell {
            display: none;
          }
        }
      }
    }
  }
  
  @include mq($until: tablet) {
    min-height: 100vh;
    .content {
      header {
        nav.gallery-nav {
          ul {
            li {
              border-bottom: 0;
              cursor: pointer;
              font-size: 10px;
              line-height: 10px;
              margin: 0 5px;
              padding: 6px 0 0;
              text-transform: uppercase;
              transition: all 0.2s;
              
              &:hover,
              &.active {
                color: $gold6;
                transition: all 0.2s;
              }
            }
          }
        }
      }
      
      .controls {
        margin: 3px 0;
      }
      
      .gallery,
      .slider,
      .slide {
        height: calc(100vh - 176px) !important;
      }
    }
  }
}

/*

    CONTACT


*/
section#contactSection {
}

ul.page-nav {
  list-style-type: none;
  padding: 10px 0 10px 10px;
  position: fixed;
  right: 0;
  top: 50vh;
  transform: translateY(calc(-50%));
  z-index: 9;
  
  li {
    cursor: pointer;
    
    a {
      display: flex;
      flex-direction: row;
      font-weight: 300;
      height: 18px;
      justify-content: flex-end;
      text-align: right;
      text-decoration: none !important;
      text-transform: uppercase;
      
      span.text {
        align-self: center;
        color: $gold6;
        font-weight: 400;
        opacity: 0;
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
        transition: opacity 0.2s;
      }
      
      span.line {
        align-self: center;
        border-bottom: 2px solid $gold6;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.9);
        margin-left: 8px;
        transition: width 0.3s;
        width: 40px;
      }
      
      &:hover {
        span.text {
          opacity: 1;
          transition: opacity 0.2s;
        }
        
        span.line {
          transition: width 0.3s;
          width: 80px;
        }
      }
    }
    
    &.active {
      a {
        span.text {
          opacity: 1;
          transition: opacity 0.2s;
        }
        
        span.line {
          transition: width 0.3s;
          width: 80px;
        }
      }
    }
  }
  
  @include mq($until: tablet) {
    display: none;
  }
}

.loading-location {
  background-color: $forest;
  height: 100vh;
  position: relative;
  width: 100%;
}
