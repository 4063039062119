:root {
  --bg-card: #6d962a;
  --bg-list-even: #f4f4f1;
  --bg-nav-mobile-hover: #d9d9d6;
  --bg-nav-mobile: #e1e1de;
  --bg-purple: #ece6ff;
  --bg-secondary: #fcfcf9;
  --bg: #ffffff;
  --border-color-active: #1a120f;
  --border-color-purple: #9781d7;
  --border-color-secondary: #f5f5f1;
  --border-color: #dfdcd4;
  --btn-card-hover: #e6ffee;
  --btn-disabled: #b0aea9;
  --btn-primary-active: #6d962a;
  --btn-primary-hover: #8e7a3f;
  --btn-primary: #1a120f;
  --btn-secondary-hover: #efe9d7;
  --grid-item: #fafaf7;
  --heart-black: #1a120f;
  --heart-like: #8e7a3f;
  --heart-unlike: #73706e;
  --status-archived: #6b1cb0;
  --status-available: #5ce646;
  --status-cancelled: #ad1506;
  --status-damaged: #e44945;
  --status-deleted: #444444;
  --status-done: #2dc962;
  --status-inprogress: #b87503;
  --status-pending: #e6a117;
  --status-processed: #06a09b;
  --status-received: #338a17;
  --status-repaired: #646bdc;
  --status-restored: #55ccee;
  --status-sendtorestoration: #75ab32;
  --status-submitted: #0b76b7;
  --tag-category-bg: #cce0ff;
  --tag-category-text: #0f274e;
  --tag-location-bg: #ffebcc;
  --tag-location-text: #604720;
  --tag-symbol-bg: #ccf9ff;
  --tag-symbol-text: #0c4d55;
  --text-black: #000000;
  --text-brown: #1a120f;
  --text-light: #b0aea9;
  --text-medium: #888681;
  --text-primary: #1a120f;
  --text-secondary: #73706e;
  --text-white: #ffffff;
}

$black: #000000;
$cream: #ecdfc5;
$dark-gray: #333333;
$dark: #1a120f;
$forest: #2b3d2d;
$light-gray: #dfdcd4;
$border-color: $light-gray;
$logo: #ecdfc5;
$medium-gray: #888681;
$nav-link: #ecdfc5;
$red: #ad1506;
$danger: $red;
$red-hover: darken($danger, 5%);
$danger-hover: darken($danger, 5%);
$success: #6d962a;
$success-hover: lighten($success, 5%);
$success-active: lighten($success, 15%);
$white: #ffffff;

$blue: #007bff;
$cyan: #17a2b8;
$green: #28a745;
$indigo: #6610f2;
$orange: #fd7e14;
$pink: #e83e8c;
$purple: #6f42c1;
$teal: #20c997;
$yellow: #ffc107;

$gold0: darken(#957b46, 10%);
$gold: #99855c;
$gold1: lighten($gold, 5%);
$gold2: lighten($gold, 10%);
$gold3: lighten($gold, 15%);
$gold4: lighten($gold, 20%);
$gold5: lighten($gold, 25%);
$gold6: lighten($gold, 30%);
$gold7: lighten($gold, 42%);
$gold8: lighten($gold, 48%);
$gold-hover: darken($gold, 5%);
$gold-active: darken($gold, 15%);

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #4d4d4d;
$gray-800: #262626;
$gray-900: #1a1a1a;
$gray-1000: #121212;

/* ----------------------------------------------
 * Generated by Animista on 2019-9-5 11:1:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-11-5 14:43:26
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
