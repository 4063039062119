.c-section--invoice {
  padding: 2em 0;
  min-height: 100vh;
}

.c-invoice {
  display: flex;
  flex-direction: column;
}

.c-invoice__items {
  margin-bottom: 3.0625em;
  width: 100%;

  &:first-child {
    margin-bottom: 3em;
  }
}

.c-invoice__wrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @include mq(desktopAd) {
    flex-direction: row;
  }
}

.c-invoice__col {
  width: 100%;
  &:last-child {
    .c-invoice__items {
      display: none;
    }
  }
  @include mq(desktopAd) {
    &:first-child {
      width: calc(35% - 2em);
    }

    &:last-child {
      width: calc(65% - 2em);

      .c-invoice__items {
        display: block;
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}

.c-invoice__attachments {
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: flex-start;
}

.c-invoice__attachment__items {
  width: 136px;
  margin: 0 1em 1em 0;
}

.c-invoice__attach__image {
  background-color: var(--bg-secondary);
  padding: 0.5em;
  border-radius: 2px;
  transition: 0.2s linear 0s;
}

.c-invoice__head {
  @include font-size(16px);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--text-primary);
  margin: 0 0 0.8125em;
}

.c-invoice__no {
  font-family: $spectral;
  @include font-size(40px);
  font-weight: 300;
  text-transform: capitalize;
  margin: 0;

  span {
    @include font-size(28px);
  }
}
.other-invoice-items {
}
.c-invoice__title {
  @include font-size(23px);
  font-weight: 500;
  margin: 0 0 1.4em;
}

.c-invoice__title.other-items {
  @include font-size(18px);
  background-color: $gold7;
  border-radius: 2px;
  color: $gold0;
  font-weight: 500;
  margin: 0 0 2em;
  padding: 0.5em 1.5em;
}
.c-link--attachment {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: url("/assets/images/icons/zoom-in.svg");
    position: absolute;
    left: calc(100% - 64%);
    top: calc(100% - 64%);
    border: 1px solid var(--border-color);
    background-color: var(--bg);
    width: 40px;
    padding: 0.576em 1em 0.3em;
    opacity: 0;
    transition: 0.2s linear 0s;
  }

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    opacity: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.69),
      rgba(255, 255, 255, 0.69)
    );
    transition: 0.2s linear 0s;
  }

  &:hover {
    &:after,
    &:before {
      opacity: 1;
    }

    .c-invoice__attach__image {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    }
  }
}

.c-invoice__collections {
  @extend .c-invoice__attachments;
  flex-direction: column;
}

.c-invoice__collections__item {
  @include font-size(16px);
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  color: #1a120f;
  cursor: pointer;
  display: flex;
  font-family: $spectral;
  font-weight: normal;
  justify-content: space-between;
  letter-spacing: normal;
  margin: 0;
  padding: 1em 0;
  text-transform: capitalize;
  transition: all 0.1s ease-out;
  width: 100%;

  &:nth-child(even) {
    background-color: $gold7;
  }

  &:hover {
    color: $black;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.4);
    z-index: 9999;
    transition: all 0.1s ease-out;

    .c-btn--modal {
      display: flex;
      background-color: var(--heart-like);
    }
  }

  @include mq(tablet) {
    height: 55px;
    padding: 0 1em;
  }
}

.c-modal__wrap {
  margin-bottom: 3em;
}
