.slide-up {
  animation: slideUp 1.2s ease;
}

.slide-down {
  animation: slideDown 1.2s ease;
}

@keyframes slideUp {
  from {
    margin-bottom: -100%;
  }
  to {
    margin-bottom: 0;
  }
}

@keyframes slideDown {
  from {
    margin-top: -100%;
  }
  to {
    margin-top: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
