.compare-list {
  display: flex;
  overflow-x: scroll;
  width: auto;

  .compare-item {
    border-right: 1px solid $gold;
    min-width: 420px;
    padding: 0 15px;
    width: 420px;

    .compare-item-image {
      img {
        height: 240px;
        max-height: 240px;
      }
    }

    .compare-item-details {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      .detail-item {
        border-bottom: 1px dotted $gold;
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        ul.sublist {
          list-style: none;
          margin: 5px 0 0 0;
          padding: 0;

          li {
            background-color: $gold7;
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 5px 10px;
          }
        }

        .qr-code {
          img {
            max-height: 80px;
          }
        }

        h6 {
          margin: 0;
        }

        p {
          margin: 0;
          max-height: 120px;
          overflow-y: auto;
          word-break: break-all;
        }
      }
    }
  }
}
