.modal-backdrop {
  align-items: center;
  animation: fade-in 200ms 1 both;
  background-color: rgba(26, 18, 15, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
}

.modal {
  background: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  padding: 15px 20px 20px 20px;
  max-width: 100%;
  
  .modal-header {
    padding: 0 20px;
  }
  
  .modal-body {
    display: block;
    padding: 0 20px;
    
    p {
      margin: 0 0 15px 0;
    }
  }
  
  .modal-footer {
    display: flex;
    padding: 0 20px;
    
    button {
      flex-grow: 1;
      margin: 5px;
      white-space: nowrap;
      justify-self: stretch;
      max-width: initial;

      &.btn-cancel {
        align-self: flex-end;
        width: auto;
        flex-grow: 0;
        flex-shrink: 1;
      }
    }
  }
}

.c-modal {
  background: #FFFFFF;
  border-radius: 2px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  &::-webkit-scrollbar:vertical {
    width: 11px;
  }
  
  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  @include mq(desktopAd) {
    width: 1250px;
  }
  @include mq(wide) {
    width: 1670px;
  }
}

.modal-body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .c-modal--requests & {
    padding-top: 6em;
  }
}

.c-modal__col {
  width: 100%;
  
  &:first-child {
    margin: 0;
  }
  
  &:last-child {
    @extend .c-modal__item__text;
  }
  
  @include mq(desktopAd) {
    &:first-child {
      width: 442px;
      position: fixed;
      height: 100%;
    }
    &:last-child {
      width: 50%;
      position: absolute;
      float: none;
      right: 4em;
      top: 4em;
    }
  }
}

.c-modal--invoice {
  .modal-body {
    padding: 5.9375em 1.5em 8em;
    @include mq(tablet) {
      padding: 3.9375em 1.5em 8em;
    }
  }
  
  .c-modal__wrap {
    @include mq(desktopAd) {
      display: flex;
      flex-direction: row;
    }
  }
  
  .c-modal__col {
    &:first-child {
      position: unset;
    }
    
    &:last-child {
      position: unset;
    }
    
    width: 100%;
    @include mq(desktopAd) {
      width: calc(50% - 2em);
    }
  }
}

.c-modal__header {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  
  .c-form__message {
    flex-grow: 2;
  }
}

.c-modal__buttons {
  align-items: flex-start;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%);
  bottom: 0;
  display: flex;
  height: auto;
  justify-content: flex-end;
  left: 0;
  padding: 110px 1em 1em;
  position: fixed;
  right: 0;
  z-index: 3;
  
  .c-btn {
    align-self: flex-end;
    max-width: fit-content;
  }
  
  button {
    span.icon {
      margin-right: 5px;
    }
  }
  
  @include mq(desktopAd) {
    justify-content: unset;
    align-items: unset;
    position: unset;
    height: auto;
    padding: 0 0 2em;
    border-bottom: 1px solid var(--border-color);
  }
}

.c-modal__details {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1.3125em;
  @include mq(desktopAd) {
    flex-direction: column;
  }
}

.c-modal__artist {
  @include font-size(17px);
  font-weight: 300;
  margin: 0 0 5px;
  @include mq(desktopAd) {
    @include font-size(21px);
  }
}

.modal-title {
  font-family: $spectral;
  @include font-size(23px);
  font-weight: 300;
  margin: 0 0 5px;
  @include mq(desktopAd) {
    @include font-size(27px);
    margin: 0 0 0.6em;
  }
}

.c-modal__subtitle {
  font-family: $sans;
  @include font-size(16px, 1.6);
  font-weight: 300;
  margin: 15px 0 0.842105263157895em;
  @include mq(desktopAd) {
    @include font-size(16px);
    margin: 0 0 0.857142em;
  }
}

.c-modal__location {
  @extend .c-works__location;
  @include font-size(14px);
}

.item-detail-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  padding: 0.9375em 0;
  
  &.asking-price {
    border-top: 1px solid var(--border-color);
    border-bottom-width: 0;
  }
  
  &:last-child {
    border-bottom: 0;
    margin-bottom: 8em;
    
    .c-section--invoice & {
      margin-bottom: 2em;
    }
  }
  
  &.is-collapsible {
    flex-direction: column;
    align-items: unset;
    
    .item-detail-wrap__title {
      margin: 0 0 1.375em;
      
      &:after {
        content: url("/assets/images/icons/arrow-bottom.svg");
        position: absolute;
        right: 0;
        transform: rotate(180deg);
        transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
      }
    }
  }
  
  &.qr-code {
    align-items: flex-start;
    
    img {
      max-height: 96px;
    }
  }
  
  &.notes-list {
    border-bottom: 0;
  }
  
  &.notes {
    .item-detail-wrap__title {
      width: 100%;
      margin-bottom: 12px;
    }
    
    .c-btn {
      margin-left: auto;
    }
  }
  
  .c-modal__item__text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    
    > span {
      align-self: center;
      
      &.progress {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.item-detail-wrap--vertical {
  align-items: flex-start;
  
  .c-modal__item__text {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    @include font-size(14px);
    width: 60%;
  }
}

.item-detail-wrap__title {
  @include font-size(14px);
  font-weight: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  @include mq(desktopAd) {
    @include font-size(16px);
  }
}

.c-modal__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-modal__list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  
  .c-modal__col {
    width: calc(50% - 1em);
    
    &:last-child {
      text-align: right;
    }
  }
}

.c-modal__status {
  display: flex;
  align-items: center;
}

.c-status {
  display: flex;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--bg-secondary);
  margin-right: 0.5em;
  animation: pulsate-bck 0.7s ease-in-out infinite both;
  
  &.c-status--available {
    background-color: var(--status-available);
  }
  
  &.c-status--damaged {
    background-color: var(--status-damaged);
  }
  
  &.c-status--restored {
    background-color: var(--status-restored);
  }
  
  &.c-status--repaired {
    background-color: var(--status-repaired);
  }
  
  &.c-status--sendtorestoration {
    background-color: var(--status-sendtorestoration);
  }
}

.c-modal__item__text {
  @include font-size(14px);
  font-weight: 500;
  color: var(--text-primary);
  @include mq(desktopAd) {
    @include font-size(16px);
  }
}

a.c-modal__item__text {
  cursor: pointer;
}

.c-modal__requests__wrap {
  width: 100%;
  
  .modal-title {
    margin: 0 0 0.4em;
  }
}

.c-modal__requests__col {
  display: flex;
  align-items: center;
  width: 100%;
  @include font-size(16px);
  font-weight: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin: 0 0 1em;
  color: var(--text-secondary);
  
  span {
    font-weight: 500;
    margin-left: 0.5em;
    color: var(--text-primary);
    text-transform: capitalize;
    white-space: nowrap;
  }
  
  @include mq(desktopAd) {
    margin: 0 4em 0 0;
  }
}

.c-modal--requests {
  .c-requests__status--pending {
    color: var(--text-white);
  }
}

.c-modal__requests__item {
  padding: 2.0625em 0;
}

.c-modal__requests--header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 0 1.125em;
  border-bottom: 1px solid var(--border-color);
  @include mq(desktopAd) {
    flex-direction: row;
  }
}

.c-modal__requests__list {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.c-modal__requests__list__item {
  @include font-size(20px);
  font-weight: normal;
  margin: 0 0 1.043478260869565em;
  @include mq(desktopAd) {
    @include font-size(23px);
  }
}

.c-modal__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  
  .c-btn {
    padding: 0.572em 2.2767em;
    
    &:first-child {
      margin-right: 1.714285714285714em;
    }
  }
}
