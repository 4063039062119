header.topbar.js-sticky-nav {
  background-color: $forest;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  font-family: $sans;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 10px;
  position: fixed;
  top: 0;
  transition: none;
  width: 100%;
  z-index: 5;
  
  .categories &,
  .locations & {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  }
  
  .logo {
    align-self: center;
    max-height: 40px;
  }
  
  ul.navbar {
    align-items: flex-end;
    align-self: center;
    display: flex;
    flex-grow: 2;
    list-style: none;
    margin: 0 0 0 2%;
    padding: 0;
    
    > li.nav-item {
      align-self: center;
      display: flex;
      flex-direction: column;
      height: 32px;
      justify-content: center;
      margin: 0 10px;
      position: relative;
      
      a.nav-link {
        align-items: flex-end;
        color: $nav-link;
        display: flex;
        font-size: 13.5px;
        font-weight: 400;
        justify-content: center;
        letter-spacing: 0.01em;
        padding: 0;
        position: relative;
        text-decoration: none;
        transition: color 0.2s, background-color 0.2s;
        white-space: nowrap;
        
        span.user-name {
          margin: 0 3px;
        }
        
        &:hover,
        &:active,
        &:focus,
        &.router-link-active {
          color: $white;
          text-decoration: none;
          transition: color 0.2s;
          
          span.arrow {
            svg {
              fill: $white;
              transition: fill 0.2s;
            }
          }
        }
      }
      
      span.arrow {
        align-self: flex-end;
        display: none;
        height: 24px;
        
        svg {
          fill: $nav-link;
          transition: fill 0.2s;
        }
      }
      
      &.search {
        height: 40px;
        margin-left: auto;
        margin-right: 0;
        min-width: 32px;
        width: auto;
        
        .input-wrap {
          align-items: center;
          display: flex;
          justify-content: center;
          position: absolute;
          right: 0;
        }
        
        .search-input {
          background: var(--bg);
          background-color: $white;
          border: 0;
          border-radius: 2px;
          color: $dark !important;
          cursor: text;
          display: none;
          padding: 0 0 0 1em;
          width: 420px;
          
          &.show {
            color: $dark;
            display: block;
          }
          
          &:focus {
            border-color: $white;
            
            & + div {
              color: $dark;
              display: block;
              
              &:hover {
                color: $dark;
                pointer-events: none;
              }
            }
          }
          
          &:not(:focus) {
            text-indent: -5000px;
          }
        }
        
        .md-icon {
          align-items: center;
          align-self: center;
          color: $nav-link;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 40px;
          justify-content: center;
          position: absolute;
          right: 5px;
          top: 0;
          z-index: 9;
          
          .material-design-icon {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
          
          &.icon-magnify {
            padding-right: 2px;
            transition: color 0.2s;
            
            &:hover {
              color: $white;
              transition: color 0.2s;
            }
          }
          
          &.icon-arrow-right {
            background-color: $white;
            border-bottom-right-radius: 2px;
            border-top-right-radius: 2px;
            color: $dark;
            height: 40px;
            right: 0;
            width: 40px;
            
            &:hover {
              border-color: $gold;
              color: $gold;
              transition: color 0.2s;
            }
            
            &.disabled {
              color: $gold1;
              pointer-events: none;
            }
          }
        }
        
        .c-form__items {
          align-items: center;
          flex-direction: row;
          margin: 0;
        }
      }
      
      &.cart {
        margin: 0 0 0 10px;
        
        a.nav-link {
          transition: color 0.2s;
          
          &:hover {
            transition: color 0.2s;
            
            svg,
            path {
              fill: $white;
              transition: fill 0.2s;
            }
            
            span.cart-count {
              color: $white;
              transition: color 0.2s;
            }
          }
        }
        
        &.shopping {
          height: 25px;
          width: 50px;
          
          svg {
            fill: $nav-link;
            max-height: 25px;
          }
          
          span.cart-count {
            color: $gold4;
            display: block;
            font-size: 14px;
            left: 1px;
            position: absolute;
            text-align: center;
            top: -8px;
            width: 100%;
          }
        }
        
        &.transfer {
          height: 30px;
          width: 50px;
          
          svg {
            fill: $nav-link;
            max-height: 30px;
            width: auto;
          }
          
          span.cart-count {
            color: $gold4;
            display: block;
            font-size: 14px;
            left: 20px;
            position: absolute;
            text-align: center;
            top: -5px;
            width: 100%;
          }
        }
        
        &.favorites {
          height: 30px;
          width: 50px;
          
          svg {
            fill: $nav-link !important;
            height: 100%;
            max-height: 30px;
            width: 100%;
          }
          
          span.cart-count {
            color: $gold4;
            display: block;
            font-size: 14px;
            left: 20px;
            position: absolute;
            text-align: center;
            top: -5px;
            width: 100%;
          }
        }
      }
      
      &.user {
        span.material-design-icon {
          line-height: 1;
        }
        
        span.user-name {
        }
        
        span.chevron-down-icon {
          transform: translateY(4px);
        }
        
        ul.dropdown-menu {
          background-color: $forest;
          border-radius: 3px;
          bottom: initial;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
          display: block;
          left: 50%;
          list-style: none;
          margin: 0;
          min-width: fit-content;
          overflow: hidden;
          padding: 0;
          position: absolute;
          right: 0;
          top: 32px;
          transform: translateX(-50%);
          width: auto;
          z-index: 9;
          
          li.nav-item {
            margin: 0;
            min-width: fit-content;
            
            a.nav-link {
              font-size: 14px;
              font-weight: 300;
              height: auto;
              justify-content: space-between;
              padding: 5px 24px;
              transition: background-color 0.2s;
            }
            
            &:hover {
              background-color: $gold0;
              transition: background-color 0.2s;
            }
            
            &:last-child {
              a.nav-link {
                padding-bottom: 8px;
              }
            }
          }
          
          &.is-open {
            bottom: initial;
            display: block;
            left: 50%;
            margin-left: 0;
            right: initial;
            top: 32px;
            transform: translateX(-50%);
          }
        }
      }
    }
    
    &.is-open {
      overflow: visible;
    }
    
    .nav-spinner {
      align-self: flex-end;
    }
  }
  
  @include mq($until: tabletLandscape) {
    align-items: flex-start;
    flex-wrap: wrap;
    .logo {
      order: 1;
    }
    .burger-wrap {
      align-self: center;
      order: 2;
    }
    ul.navbar {
      order: 3;
    }
    ul.navbar {
      background-color: transparent;
      display: none;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      overflow: hidden;
      padding: 10px 0;
      width: 100%;
      z-index: 3;
      
      li.nav-item {
        margin: 0;
        text-align: center;
        
        a.nav-link {
          align-self: center;
          border: 0;
          color: $nav-link;
          display: flex;
          font-size: 17px;
          font-weight: 300;
          padding: 5px 0;
          width: auto;
          
          &:hover {
            color: $white;
          }
        }
        
        &.search {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 0;
          min-height: 40px;
          order: 1;
          
          .input-wrap {
            max-width: 100%;
            position: relative;
            right: initial;
            top: initial;
            width: 100%;
            
            .search-input {
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
              position: relative;
              
              &.show {
                width: 100%;
              }
            }
          }
          
          .md-icon {
            top: initial;
            transform: initial;
            
            &.icon-magnify {
              position: relative;
            }
            
            &.icon-arrow-right {
              position: absolute;
              right: 0;
              
              &:hover {
                color: $gold;
                transition: color 0.2s;
              }
            }
          }
        }
        
        &.locations {
          order: 2;
        }
        
        &.categories {
          order: 3;
        }
        
        &.latest {
          order: 4;
        }
        
        &.collections {
          order: 5;
        }
        
        &.merchants {
          order: 6;
        }
        
        &.cart {
          margin: 0;
          width: 100% !important;
          
          a.nav-link {
            width: 100%;
            
            svg {
              width: 20px;
            }
            
            .cart-count {
              left: initial;
              margin-left: 10px;
              position: initial;
              top: initial;
              width: auto;
              
              &::before {
                content: "(";
                height: 100%;
                margin-right: 2px;
              }
              
              &::after {
                content: ")";
                height: 100%;
                margin-left: 2px;
              }
            }
          }
          
          &.favorites {
            order: 8;
          }
          
          &.shopping {
            order: 7;
          }
          
          &.transfer {
            order: 8;
          }
        }
        
        &.user {
          order: 8;
        }
      }
      
      &.is-open {
        display: flex;
        margin: 0;
        
        li.nav-item {
          width: 100%;
        }
      }
    }
    ul.navbar.icons {
      &.is-open {
        justify-content: flex-end;
      }
    }
  }
  @include mq($until: tablet) {
    li.nav-item.search {
      right: 0;
      width: 100%;
    }
  }
}

.secondary-header {
  background-color: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 2;
  
  > div {
    align-items: center;
    display: flex;
  }
  
  .total-results {
    align-items: center;
    align-self: center;
    color: $dark;
    display: flex;
    font-size: 17px;
    font-weight: 300;
    justify-content: flex-start;
    margin: 0 auto;
    text-align: center;
    
    span {
      align-self: center;
      
      &.progress {
        display: flex;
        flex-direction: column;
        height: 25px;
        justify-content: center;
        padding-top: 3px;
      }
    }
  }
  
  .layout-btn-wrap {
    justify-content: flex-end;
    
    .c-btn.c-btn--view {
      margin: 0 0 0 0.5rem;
    }
  }
  
  @include mq($until: tablet) {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0 0;
    > div {
      justify-content: center;
      padding-bottom: 10px;
      
      label {
        text-align: center;
        width: 100%;
      }
    }
    .filter-wrap {
      order: 1;
      
      button.c-btn.c-btn--filter.js-mobile {
        max-width: initial;
        width: auto;
      }
    }
    .total-results {
      align-self: center;
      display: block;
      font-size: 14px;
      margin: 0;
      order: 2;
      padding: 0 10px 10px 10px;
      text-align: center;
      white-space: nowrap;
      width: auto;
    }
    .layout-btn-wrap {
      margin-right: 10px;
      order: 3;
      
      label {
        display: none;
      }
    }
  }
}

.burger-wrap {
  display: none;
  @include mq($until: tabletLandscape) {
    display: flex;
    justify-content: center;
    margin-left: auto;
    order: 2;
    padding-left: 10px;
  }
}

.c-navigation__submenu {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1em;
  @include mq(desktop) {
    max-width: calc(1174px - 2em);
  }
  @include mq(wide) {
    max-width: calc(1264px - 2em);
  }
}

.c-navigation__submenu__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.5em;
  position: relative;
  
  .c-btn--close {
    left: 0;
    padding: 0.844em;
    position: relative;
    top: 0;
  }
}

.c-navigation__submenu__title {
  @include font-size(28px);
  font-family: $spectral;
  margin: 0;
}

.js-fixed-nav {
  animation: fadeIn 0.3s ease-out forwards;
  left: 0;
  position: fixed;
  right: 0;
  top: -157px;
  z-index: 4;
}

.js-fixed-animate {
  .c-section--header {
    top: 0;
  }
  
  .c-section--location,
  .c-section--locations {
    margin-top: 85px;
  }
  
  .has-modal & {
    .c-section--header {
      position: unset;
    }
  }
}

@keyframes fadeIn {
  0% {
    transform: translateY(-74px);
  }
  100% {
    transform: translateY(0);
  }
}

// hamburger
.hamburger {
  align-self: center;
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  height: 24px;
  margin: 0;
  overflow: visible;
  text-transform: none;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
  
  &:hover {
    opacity: 0.7;
  }
  
  &.is-active {
    &:hover {
      opacity: 0.7;
    }
    
    .hamburger-inner {
      background-color: $gold5;
      
      &::before,
      &::after {
        background-color: $gold5;
      }
    }
  }
}

.hamburger-box {
  display: inline-block;
  height: 24px;
  outline: none;
  position: relative;
  width: 30px;
}

.hamburger-inner {
  background-color: $gold5;
  display: block;
  height: 1px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 30px;
  
  &::before,
  &::after {
    background-color: $gold5;
    height: 1px;
    position: absolute;
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease;
    width: 30px;
  }
  
  &::before,
  &::after {
    content: "";
    display: block;
  }
  
  &::before {
    top: -10px;
  }
  
  &::after {
    bottom: -10px;
  }
}

.hamburger--3dy {
  outline: none;
  padding: 0;
  
  .hamburger-box {
    perspective: 80px;
  }
  
  .hamburger-inner {
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    
    &::before,
    &::after {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  
  &.is-active .hamburger-inner {
    background-color: transparent !important;
    transform: rotateX(-180deg);
    
    &::before {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    
    &::after {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
  }
}
