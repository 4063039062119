.range-slider {
  width: 100%;
  text-align: center;
  position: relative;
  height: 5em;

  &.disabled {
    pointer-events: none;
    opacity: 0.2;

    > input {
      pointer-events: none;
    }
  }
}

.range-slider svg,
.range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--border-color);
}

input[type="range"]:focus::-ms-fill-lower {
  background: var(--border-color);
}

input[type="range"]:focus::-ms-fill-upper {
  background: var(--border-color);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: var(--border-color);
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  height: 18px;
  color: #FFFFFF;
  width: 18px;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input.min-range::-webkit-slider-thumb {
  border: 1px solid $gold;
  background: rgba($gold2, .6);
  transform: translateY(4px)
}

input.max-range::-webkit-slider-thumb {
  border: 1px solid $gold;
  background: rgba($gold2, .6);
  transform: translateY(-4px)
}

.c-filter__range {
  .range-title {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
  }

  .range-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    position: relative;

    span {
      font-size: 20px;
      width: 12px;

      &.dollar-sign {
        margin-right: 4px;
      }

      &.plus-sign {
        display: block;
        position: absolute;
        visibility: hidden;
        font-size: 13px;
        font-weight: 600;
        right: 2px;
        z-index: 999;

        &.visible {
          visibility: visible;
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin-left: 6px;
    }

    .min {
      margin-right: auto;
    }

    .max {}
  }

  .c-form__col {
    align-self: flex-end;
    margin-top: 25px;
  }
}

.text-input {
  border-radius: 0;
  border: 1px solid $dark;
  color: $dark;
  font-weight: 500;
  max-width: 100px;
  font-size: 16px;
  padding: 4px 12px;
  text-align: right;
  width: auto;

  &:first-child {
    margin-right: auto;
  }

  &:last-child {}

  &:focus {
    border-color: $gold1;
  }

  &.asking-price {
    min-width: 130px;
    height: 30px;
    padding: 4px 8px;
  }

  &.price-range {
    min-width: 120px;
    border-radius: 4px;
    border: 1px solid $gold6;
  }
}

.airtable-embed {
  width: 100%;
  height: 3550px;
  border: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none !important;
  transition: background-color 0.4s !important;
}

::-webkit-scrollbar:vertical {
  width: 12px !important;
}

::-webkit-scrollbar:horizontal {
  height: 12px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  border: 2px solid white !important;
  background-color: rgba($gold1, 0.5) !important;

  &:active,
  &:hover {
    transition: background-color 0.4s !important;
    background-color: rgba($gold1, 1) !important;
  }
}

// pagination
.pagination-wrap {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;

  &.top {
    width: 100%;
    margin-top: 40px;
  }

  @include mq($until: desktop) {
    ul.pagination {
      li.page-item {
        a.page-link {
          padding: 4px 10px !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 2px;
}

.page-link {
  position: relative;
  display: none;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $dark;
  background-color: #FFFFFF;
  border: 1px solid var(--border-color);
}

.page-link:hover {
  z-index: 2;
  color: $white;
  text-decoration: none;
  background-color: $dark;
  border-color: $dark;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.page-item.active .page-link {
  color: #FFFFFF;
  background-color: $dark;
  border-color: $dark;
}

.page-item.disabled .page-link {
  color: #6C757D;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #DEE2E6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.checkbox-wrap {
  position: relative;
  flex-grow: 1;

  .form-group {
    display: flex;
  }
}

input.squarecheck {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  margin-left: -15px;
}

input.squarecheck + label {
  display: inline-block;
  margin: 1px 10px 0 32px;
  cursor: pointer;
  align-self: center;
  line-height: 1.25em;
  white-space: nowrap;
}

input.squarecheck + label:before {
  content: " ";
  width: 22px;
  height: 22px;
  top: 0;
  left: 0;
  position: absolute;
  transition: none;
  border: 1px solid $dark;
}

input.squarecheck:checked + label:before {
  background-color: $gold;
  border-color: $gold;
}

input.squarecheck.success:checked + label:before {
  background-color: $success !important;
  border-color: $success !important;
}

input.squarecheck + label:after {
  display: none;
}

input.squarecheck:checked + label:after {
  content: url("/assets/images/icons/check_white.svg");
  width: 16px;
  height: 16px;
  top: 1px;
  left: 3px;
  position: absolute;
  transition: none;
  display: block;
}

input.squarecheck:checked + label:hover {
  &:after {
    display: block;
  }
}

.price-checkboxes {
  margin-top: 30px;

  .checkbox-wrap {
    input.squarecheck + label {
      margin-top: 2px;
    }

    input.squarecheck:checked + label:after {
      content: url("/assets/images/icons/check_white.svg");
      width: 14px;
      height: auto;
      top: 1px;
      left: 3px;
      position: absolute;
      transition: none;
      display: block;
    }
  }
}

.cleave {
  .checkbox-wrap {
    position: relative;
    flex-grow: 1;
    justify-self: flex-start;
    align-self: flex-start;

    label {
      margin-top: 4px !important;
    }

    .form-group {
      display: flex;
    }
  }

  input.squarecheck {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    margin-left: -15px;
  }

  input.squarecheck + label {
    display: inline-block;
    margin: 0 10px 0 38px;
    cursor: pointer;
    align-self: center;
    line-height: 1.25em;
    white-space: nowrap;
  }

  input.squarecheck + label:before {
    content: " ";
    width: 28px;
    height: 28px;
    top: 0;
    left: 0;
    position: absolute;
    transition: none;
    border: 1px solid $dark;
  }

  input.squarecheck + label:after {
    content: url("/assets/images/icons/check_white.svg");
    left: 0;
    position: absolute;
    transition: none;
    display: none;
  }

  input.squarecheck:checked + label:before {
    background-color: $gold;
    border-color: $gold;
  }

  input.squarecheck:checked + label:after {
    display: block;
    top: 5px;
    left: 5px;
    width: 18px !important;
    height: 18px !important;
  }

  input.squarecheck:checked + label:hover {
    &:after {
      display: block;
    }
  }
}

.c-alert {
  height: 92px !important;
  padding: 1.5em !important;
  background: var(--bg) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 2px !important;
  color: var(--text-primary) !important;
  font-weight: 500 !important;
}

.is-hidden {
  display: none !important;
}

.c-arrow {
  display: none;
  align-items: center;

  @include mq(tablet) {
    display: flex;
  }
}

.c-link--arrow {
  display: flex;

  &:first-child {
    margin-right: 2.3125em;
  }

  &.is-disable {
    svg {
      path {
        stroke: var(--text-light);
      }
    }
  }
}

.c-tag {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 0;
}

.c-tag__items {
  display: flex;
  align-items: center;
  padding: 0.428571em 0.857142em;
  @include font-size(14px);
  font-weight: 500;
  border-radius: 2px;
  height: 32px;
  white-space: nowrap;
  margin: 0 0.571428571428571em 0.5em 0;

  @include mq(desktop) {
    margin: 0 0.571428571428571em 0 0;
  }
}

.c-tag--location {
  background-color: var(--tag-location-bg);
  color: var(--tag-location-text);
}

.c-tag--category {
  background-color: var(--tag-category-bg);
  color: var(--tag-category-text);
}

a.c-tag--category {
  cursor: pointer;
}

.c-tag--symbol {
  background-color: var(--tag-symbol-bg);
  color: var(--tag-symbol-text);
}

.c-section--breadcrumb {
  padding: 2em 0;
}

.c-breadcrumb {
  @include font-size(14px);
  display: flex;
  align-items: center;
  color: var(--text-secondary);

  span {
    display: flex;
    align-items: center;
  }
}

.c-breadcrumb__slash {
  margin: 0 0.5em;
}

.c-link--breadcrumb {
  color: var(--text-primary);

  &:hover {
    color: var(--btn-primary-hover);
  }
}

.agile__actions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .has-one-slide & {
    display: none;
  }

  @include mq(desktopAd) {
    left: 0;
    right: 0;
  }

  .categories &,
  .locations & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    transform: translateY(58px);
  }
}

// carousel
.agile {
  padding: 0;
  opacity: 0;

  .agile__list {
    margin-bottom: 1.5625em;
    z-index: 3;

    .agile__track {
      .agile__slides {
        a.slide {}

        &.agile__slides--regular {
          justify-content: center;
        }
      }
    }

    img {
      max-height: 50vh;
    }

    @include mq($until: desktopAd) {
      img {
        max-height: 600px;
      }
    }
  }

  @include mq(desktopAd) {
    padding: 0;
  }

  &.main {
    opacity: 0;
  }

  &.fade-in {
    opacity: 1;
  }
}

.loading-agile {
  position: absolute;
  width: 100%;
  top: 160px;
}

.slide.agile__slide:not(.slide--thumbnail) {
  align-items: center;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  height: 350px;
  justify-content: center;
  text-align: center;
  align-self: stretch;

  @include mq($until: tablet) {
    height: 200px;
  }
}

.slide img {
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}

.slide--thumbnail {
  margin-right: 1em;
  cursor: pointer;
  height: 100px;
  padding: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.75;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  &.agile__slide {
    flex-grow: unset;
  }

  @include mq($until: tablet) {
    height: 60px;
  }
}

.agile__nav-button {
  width: 48px;
  height: 48px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  z-index: 3;
  background-color: rgba(#f0f0f0, 0.5);
  transition: background-color 0.3s;

  &[disabled] {
    opacity: 0.85;
    cursor: not-allowed;
    visibility: hidden;
  }

  &:hover {
    transition: background-color 0.3s;
    background-color: #E0E0E0;
  }
}

.agile__nav-button--next {
  border-radius: 2px 0 0 2px;
}

.agile__nav-button--prev {
  border-radius: 0 2px 2px 0;
}

.thumbnails__wrap {
  display: block;
  margin-bottom: 15px;
  opacity: 0;

  &.fade-in {}

  .agile__list {
    margin-bottom: 0;
  }

  .agile__nav-button {
    width: 25px;
    height: 25px;
    opacity: 0;
    z-index: 3;
    transition: 0.2s linear 0s;

    &:hover {
      opacity: 1;
    }
  }
}

.agile__nav-button--hidden {
  background: transparent;
  position: absolute;
  width: 48px;
  padding: 0;
}

// pagination
.c-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-pagination__items {
  margin-right: 0.5em;

  &:last-child {
    margin-right: 0;
  }
}

.c-pagination--dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #DFDCD4;
  background-color: var(--bg);
}

.c-link--pagination {
  @extend .c-pagination--dots;
  border-radius: 2px;
  font-weight: 500;

  span {
    @include font-size(14px);
    display: none;

    @include mq(tablet) {
      display: block;
    }
  }

  &.is-active {
    background-color: var(--btn-primary);
    color: var(--text-white);
  }

  &:hover {
    background-color: var(--btn-primary);
    color: var(--text-white);
  }
}

.c-pagination--prev {
  .c-link--pagination {
    width: 40px;
    padding: 0;
    font-weight: normal;
    letter-spacing: 0.02em;

    svg {
      path {
        transition: 0.2s linear 0s;
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--bg);
        }
      }
    }

    @include mq(tablet) {
      justify-content: space-between;
      width: 97px;
      padding: 0 0.9em 0 0.7em;
    }
  }
}

.c-pagination--next {
  @extend .c-pagination--prev;

  .c-link--pagination {
    width: 40px;

    @include mq(tablet) {
      width: 72px;
    }
  }
}

.btn-wrap {
  display: flex;
  justify-content: center;

  > div {
    margin: 0 10px;
  }
}

.bg-red {
  background-color: #CA0000;
}

.red-border {
  border: 1px solid #CA0000;
}

.bg-blue {
  background-color: #0000CA;
}

.blue-border {
  border: 1px solid #0000CA;
}

.bg-green {
  background-color: #00AA00;
}

.green-border {
  border: 1px solid #00AA00;
}

.bg-forest {
  background-color: $forest;
}

.bg-gold {
  background-color: $gold;
}

.bg-success {
  background-color: $success !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-dark {
  background-color: $dark !important;
}

.lifecycle-spinner {
  height: 18px;
  width: 18px;
}

section.wrapper.dark {
  background: #313134;
}

.spinner-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 100px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

  #artInventoryPage & {
    left: 190px;

    @media screen and (max-width: 979px) {
      left: 0;
    }
  }
}

div.spinner {
  -moz-animation: rotate 10s infinite linear;
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
  position: relative;
  display: block;
  margin: auto;
  width: 142px;
  height: 142px;
}

div.spinner i {
  -moz-animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  -webkit-animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
  -moz-transform-origin: 50% 100% 0;
  -webkit-transform-origin: 50% 100% 0;
  transform-origin: 50% 100% 0;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  border: solid 6px transparent;
  border-bottom: none;
}

div.spinner i:nth-child(1) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.3, 0.12, 0.03);
  width: 44px;
  height: 22px;
  margin-top: -22px;
  margin-left: -22px;
  border-color: #957B46;
  border-top-left-radius: 36px;
  border-top-right-radius: 36px;
}

div.spinner i:nth-child(2) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.6, 0.24, 0.03);
  width: 58px;
  height: 29px;
  margin-top: -29px;
  margin-left: -29px;
  border-color: #A6894E;
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;
}

div.spinner i:nth-child(3) {
  -moz-animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  animation-timing-function: cubic-bezier(0.09, 0.9, 0.36, 0.03);
  width: 72px;
  height: 36px;
  margin-top: -36px;
  margin-left: -36px;
  border-color: #B2965C;
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
}

div.spinner i:nth-child(4) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.2, 0.48, 0.03);
  width: 86px;
  height: 43px;
  margin-top: -43px;
  margin-left: -43px;
  border-color: #BAA16D;
  border-top-left-radius: 54px;
  border-top-right-radius: 54px;
}

div.spinner i:nth-child(5) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.5, 0.6, 0.03);
  width: 100px;
  height: 50px;
  margin-top: -50px;
  margin-left: -50px;
  border-color: #C3AC7E;
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
}

div.spinner i:nth-child(6) {
  -moz-animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  animation-timing-function: cubic-bezier(0.09, 1.8, 0.72, 0.03);
  width: 114px;
  height: 57px;
  margin-top: -57px;
  margin-left: -57px;
  border-color: #CBB790;
  border-top-left-radius: 66px;
  border-top-right-radius: 66px;
}

div.spinner i:nth-child(7) {
  -moz-animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  animation-timing-function: cubic-bezier(0.09, 2.1, 0.84, 0.03);
  width: 128px;
  height: 64px;
  margin-top: -64px;
  margin-left: -64px;
  border-color: #D3C3A1;
  border-top-left-radius: 72px;
  border-top-right-radius: 72px;
}

div.spinner i:nth-child(8) {
  -moz-animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  -webkit-animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  animation-timing-function: cubic-bezier(0.09, 2.4, 0.96, 0.03);
  width: 142px;
  height: 71px;
  margin-top: -71px;
  margin-left: -71px;
  border-color: #EFE9DC;
  border-top-left-radius: 78px;
  border-top-right-radius: 78px;
}

@-moz-keyframes rotate {
  to {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.c-section--notfounds {
  min-height: 100vh;
}

.message-box {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 2px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 7.375em 5px;
  width: 600px;
  max-width: calc(100vw - 20px);
  margin: 120px auto;
  text-align: center;

  .c-section--notfounds & {
    padding: 4em 0;
  }
}

.c-not-found.message-box--cart {
  margin: 4em 1em 0;

  @include mq(tablet) {
    margin: 4em auto 0;
    width: 100%;
  }
}

.message-box__title {
  @include font-size(21px);
  font-weight: 500;
  color: var(--text-primary);
  margin: 0;
}

.message-box__text {
  color: var(--text-secondary);
  font-weight: normal;
  @include font-size(16px);
  margin: 0;

  .c-section--notfounds & {
    margin-bottom: 2em;
  }
}

.message-box__subhead {
  font-family: $spectral;
  @include font-size(64px);
  font-weight: 500;
  color: var(--btn-primary-hover);
  margin: 0;
}

.c-btn--notfound {
  padding: 0.572em 2.2724em;
}

.add-item {
  iframe {
    min-height: 4000px;
    overflow: hidden;
  }
}

.royal-text {
  background: linear-gradient(130deg, #DED0BF 0%, #A38C72 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fancybox-slide.fancybox-slide--iframe {
  padding: 20px;

  @include mq($until: mobileLandscape) {
    padding: 0;
  }
}

iframe.fancybox-iframe {
  min-height: 100%;
  height: 100%;
}

.ty2 {
  transform: translateY(2px);
}

.fade-in {
  animation: fadein 1.5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
    visibility: visible;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes appear {
  from {
    transform: translate(-50%, -40%);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/*

  DATEPICKER


*/
.datepicker-wrap {
  .pickers {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    > div {
      label {
        display: block;
        margin: 0 0 2px 2px;
        font-size: 14px;
      }
    }

    .c-dropdown__title {
      margin-right: 0;
    }
  }

  .dash {
    color: var(--border-color);
    font-size: 18px;
    transform: translateY(9px);
    padding: 0 15px;
  }
}

.vdp-datepicker {
  font-family: $sans;

  .day__month_btn {
    font-family: $sans;
  }

  input.c-dropdown__title.js-dropdown-title {
    background-color: transparent;
    width: 120px;
    margin-right: 8px;
    color: var(--text-primary);
    padding: 0.9em 1em;
  }

  .calendar-wrap {
    position: absolute;
    font-family: $sans;
    margin-top: 10px;
    left: 0;
    height: 290px;
    width: 296px;
    z-index: 9;

    .cell.day:not(.blank) {
      &:hover {
        border: 0 !important;
        background-color: $forest;
        color: $white;
        outline: none;
        box-shadow: none;
      }

      &.day.today {
        background-color: $gold;
        color: $white;
      }

      &.day.selected {
        background-color: $forest;
        color: $white;
      }
    }

    &.end {
      margin-left: -176px;
    }
  }

  .date-range & {
    input.c-dropdown__title.js-dropdown-title {
      margin-right: 0;
    }
  }
}

.transfer-list {
  .calendar-wrap {
    left: initial;
    right: 0;
  }

  @include mq($until: tablet) {
    .calendar-wrap {
      position: fixed;
      left: 50%;
      top: 50%;
      right: initial;
      max-width: 100%;
      transform: translate(-50%, -50%);
    }
  }
}

input.cycad-radio {
  margin-left: -15px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

input.cycad-radio + label {
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 2px 10px;
  text-align: center;
  width: 46px;
}

input.cycad-radio:checked + label {
  background-color: $dark;
  border-color: $dark;
  color: $white;
}

.badge {
  color: $white;
  font-size: 13px;
  margin-left: auto;
  min-width: 120px;
  padding: 0.3em 0.9em;
  text-align: center;
  text-transform: uppercase;

  &.badge-danger {
    background-color: $red;
  }

  &.badge-success {
    background-color: $success;
  }
}

.progress-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .transfer-details & {
    svg {
      transform: translateX(23px);
    }
  }
}

.progress-loader {
  position: absolute;
  top: 0;
  width: 0%;
  height: 5px;
  background-color: $forest;
}

.bold {
  font-weight: bold;
}

.material-design-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1em;
}

.btn-remove {}

input.selected-option {
  appearance: none;
  outline: none;
  border: none;
  width: fit-content;
  display: inline;
  cursor: pointer;
  min-width: 0;
  font-family: inherit;
  color: var(--text-primary);
  background-color: transparent;
}
