.c-filter {
  display: flex;
  flex-direction: column;
  padding: 0;
  .filters-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include mq(desktop) {
    padding: 0;
  }
}

.c-filter__header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;

  span {
    margin-left: 5px;
  }

  .c-filter__header__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
    
    @include mq(desktop) {
      width: 100%;
      .js-close {
        display: none;
      }
    }
  }
  @include mq($until: tablet) {
    .c-filter__header__row {
      padding-bottom: 1rem;
      h3 {
        margin-right: 0;
      }
      button {
        margin: 0;
      }
      button.c-link.c-link--close.js-close {
        align-items: center;
        border: 1px solid $gray-600;
        display: flex;
        flex-direction: column;
        height: 28px;
        justify-content: center;
        margin-right: 2px;
        padding: 0;
        width: 28px;
        svg {
          path {
            stroke: $gray-800;
          }
        }
        &:hover {
          border-color: $black;
          color: $black;
          svg {
            path {
              stroke: $black !important;
            }
          }
        }
      }
    }
  }
}

.c-filter__title {
  display: flex;
  align-items: center;
  @include font-size(19px);
  font-weight: 500;
  color: var(--text-primary);
  margin: 0 1.5em 0 0;

  span {
    @include font-size(14px);
    font-family: $sans;
    color: var(--text-brown);
    margin-left: 0.5em;
  }

  svg {
    margin-right: 0.421052631578947em;
    width: 15px;
  }

  @include mq(desktop) {
    margin: 0;
    @include font-size(23px);
    svg {
      display: none;
    }
  }
}

.c-filter__fav {
  margin-bottom: 1.5em;

  .c-filter__items__title {
    &::after {
      align-self: center;
      display: none;
    }

    &.is-selected {
      color: var(--btn-primary-active);
    }
  }
}

.c-filter__items {
  position: relative;
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--border-color);

  .dropdown-loader {
    position: absolute;
    right: 30px;
    top: 0;
    line-height: 0;
  }

  &.range-wrap {
    padding-bottom: 2em;
    overflow: hidden;

    .checkbox-wrap {
      margin: 15px 0 0 0;
    }

    .units-label {
      margin: 3px 0 0 6px;
      font-size: 15px;
      align-self: center;
    }
  }

  .unit-radios {
    display: flex;
    margin-top: 40px;
    justify-content: center;

    .unit-wrap {
      position: relative;
      margin: 0;
    }
  }

  .search-form {
    width: 100%;

    input.c-form__input {
      width: 100%;
    }
  }

  &.is-closed {
    padding-bottom: 1em;

    .c-filter__items__title {
      margin: 0;

      &:after {
        transform: rotate(0) translateY(-4px);
      }
    }

    .c-filter__list,
    .c-filter__range,
    .unit-radios,
    .datepicker-wrap {
      display: none;
    }

    .search-form {
      display: none;
    }
  }

  &:focus-within {
    .c-filter__items__title {
      margin: 0 0 0.5em;

      &:after {
        transform: rotate(180deg);
      }
    }

    .c-filter__list {
      display: flex;
    }
  }

  &.c-filter--submit {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    text-align: center;
    margin: 0;
    padding: 1em 0;
    border-bottom: none;
    display: block;
    @include mq(desktop) {
      display: none;
    }
  }
}

.c-filter__items__title {
  position: relative;
  display: flex;
  align-items: center;
  @include font-size(19px);
  font-weight: 500;
  margin: 0 0 0.5em;
  cursor: pointer;

  &:after {
    content: url("/assets/images/icons/arrow-bottom.svg");
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(180deg);
    transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .c-filter__available & {
    margin: 0;

    &:after {
      display: none;
    }
  }
}

.sort-by-list li.c-filter__list__items {
  display: flex;
  width: 100%;
  justify-content: flex-start;

  span.direction-arrow {
    align-self: center;
    font-size: 22px;
    line-height: 19px;
    text-align: center;
    margin: 0 0 0 auto;
    padding: 0 2px;
    cursor: pointer;

    &.asc {
      &::before {
        content: "\2191";
      }
    }

    &.desc {
      &::before {
        content: "\2193";
      }
    }
  }
}

ul.c-filter__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  width: 100%;

  li.c-filter__list__items {
    &.active {
      .c-link:first-child {
      }
    }

    div.li-inner {
      margin: 0 0 0.125em;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      a.c-link--filter {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        padding: 5px 12px 5px 25px;
        border-radius: 2px;
        transition: none;
        width: calc(100% - 34px);

        span {
          @include font-size(14px);
          color: var(--text-secondary);
          margin-left: 0.5em;
        }

        &::before {
          content: "";
          width: 24px;
          height: 24px;
          top: 4px;
          left: 0;
          background-color: transparent;
          position: absolute;
          transition: none;
          border: 1px solid $black;
        }

        &::after {
          display: none;
        }

        &.is-selected {
          transition: none;

          &::before {
            background-color: $gold1;
            border-color: $gold1;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            left: 8px;
            top: 6px;
            transform: rotate(38deg);
            height: 14px;
            width: 8px;
            border-bottom: 2px solid #ffffff;
            border-right: 2px solid #ffffff;
          }

          &:hover {
            &::after {
              display: block;
            }
          }
        }

        &:hover {
          color: $black;

          span {
            color: var(--btn-primary-hover);
          }
        }
      }

      .expand {
        align-self: center;
        position: relative;
        display: flex;
        align-items: center;
        height: 24px;
        width: 24px;
        cursor: pointer;
        background-color: $gold7;

        &::after {
          content: url("/assets/images/icons/arrow-bottom.svg");
          position: absolute;
          right: 6px;
          top: 2px;
          transform: rotate(180deg);
          transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        &.is-closed {
          &::after {
            transform: rotate(0) translateY(-4px);
          }
        }

        &:focus-within {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }

    ul.sub-list {
      position: relative;
      padding-left: 25px;
      margin-left: 12px;
      width: 100%;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        border-left: 1px solid var(--border-color);
        height: 100%;
      }

      li.c-filter__list__items {
        div.li-inner {
          span.line {
            display: block;
            position: absolute;
            transform: translate(-24px, 16px);
            width: 22px;
            height: 1px;
            border-top: 1px solid var(--border-color);
          }

          a.c-link--filter {
            padding: 5px 12px 5px 25px;

            &::before {
              width: 16px;
              height: 16px;
              top: 8px;
              left: 0;
            }

            &.is-selected {
              &::after {
                content: "";
                display: block;
                position: absolute;
                top: 9px;
                left: 5px;
                transform: rotate(38deg);
                height: 10px;
                width: 6px;
                border-bottom: 2px solid #ffffff;
                border-right: 2px solid #ffffff;
              }
            }
          }
        }
      }
    }

    span.label {
      color: $red;
    }

    span.collection-count {
      margin-left: auto;
    }
  }
}

.c-dashboard__filter {
  display: flex;
  justify-content: center;

  .c-btn {
    margin: 0;
  }

  @include mq(desktop) {
    display: none;
  }
  @include mq($until: mobileLandscape) {
    flex-direction: column;
    margin: 0;
    width: 100%;
    .btn-cancel {
      display: block;
      width: auto;
      margin: 15px 10px 0;
    }
  }
}

.c-btn--filter {
  background-color: var(--bg);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  font-weight: normal;
  text-transform: none;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.6em;
  }

  &:hover {
    color: var(--text-white);
    border-color: var(--btn-secondary-hover);

    svg {
      path {
        stroke: white;
      }
    }
  }

  &.btn-sort-direction {
    min-width: 112px;
  }

  @include mq($until: desktop) {
    width: auto !important;
    margin: 0 10px !important;
  }

  #artInventoryPage & {
    align-self: center;
  }
}

.c-filter__search {
  width: 100%;
  margin-bottom: 1em;
  display: block;

  .c-form--search {
    display: flex;
  }
}

.c-filter__dropdown {
  @extend .c-filter__search;
  margin-bottom: 1.5em;
}

.c-btn--done {
  width: 95%;
}

.c-filter__selected {
  border-top: 1px solid var(--border-color);
  min-height: 72px;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: 0.8em 0;

  .no-filters-selected {
    @include font-size(13px);
    color: var(--text-secondary);
    padding: 0 3px;
  }

  &.has-print-mode {
    display: none;
  }
}

.c-filter__selected__title {
  @extend .c-filter__items__title;
  @include font-size(14px);
  margin: 0 0 1em;
  cursor: text;

  &:after {
    display: none;
  }
}

.c-filter__selected__list {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -3px;
  min-height: 26px;
  max-height: 120px;
  align-items: flex-start;
  overflow: auto;
}

.v-switch-core {
  background-color: var(--btn-primary) !important;

  .vue-js-switch.toggled & {
    background-color: var(--btn-primary-hover) !important;
  }
}

.c-filter__available {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 0 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--border-color);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
