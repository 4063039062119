.c-dropdown {
  position: relative;
  overflow: hidden;
  @include mq($until: mobileLandscape) {
    width: 100%;
    margin: 0 0 10px !important;
  }

  &.quality-dropdown {
    min-width: 160px !important;
  }

  &.is-open {
    overflow: unset;

    .c-dropdown__title {
      white-space: nowrap;
      padding-right: 20px;

      &:after {
        transform: rotate(180deg);
      }
    }

    .c-dropdown__list {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      z-index: 15;
    }
  }

  &:focus-within {
    overflow: unset;

    .c-dropdown__title {
      &:after {
        transform: rotate(180deg);
      }
    }

    .c-dropdown__list {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      z-index: 15;
    }
  }

  &.c-dropdown--sort {
    margin-right: 0.5em;

    .modal-backdrop {
      display: none;
    }

    .c-dropdown__list {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      width: 230px;
      min-height: 320px;
      margin: 0 auto;
      overflow: hidden;
      @include mq(tablet) {
        position: absolute;
        transform: none;
        top: auto;
        min-height: auto;
        width: 100%;
      }
    }

    &.is-open {
      .modal-backdrop {
        display: flex;
        @include mq(tablet) {
          display: none;
        }
      }
    }

    &.quality-dropdown {
      min-width: 130px;
      margin-right: 0;
    }
  }

  @include mq($until: mobileLandscape) {
    width: 100%;
    margin: 0 0 10px;
  }
}

.c-dropdown__list__items--header {
  font-family: $sans;
  font-weight: 500;
  font-size: 1rem;
  background: #f5f5f2;
  padding: 0.875em;
  height: 49px;
  display: flex;
  align-items: center;
  pointer-events: none;
  @include mq(tablet) {
    display: none;
  }
}

.c-dropdown__list {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: var(--bg);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  border: none;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  width: 100%;
  height: auto;
  z-index: 2;
  transition: all 330ms ease-out;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.c-dropdown__title {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  @include font-size(14px);
  font-weight: normal;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  height: 40px;
  padding: 0.9em 2.5em 0.9em 1em;
  cursor: pointer;
  transition: all 330ms ease-out;

  span {
    margin-left: 0.571428571428571em;

    &.c-dropdown__text {
      margin-left: 0.3em;
      @include mq(tablet) {
        display: block;
      }
    }
  }

  &:after {
    content: url("/assets/images/icons/arrow-bottom.svg");
    position: absolute;
    right: 1em;
    transition: all 330ms ease-out;
  }
}

.c-link--dropdown {
  @include font-size(14px);
  padding: 0.75em 2.75em 0.75em 1em;
  display: flex;
  position: relative;

  &:after {
    content: url("/assets/images/icons/check.svg");
    position: absolute;
    right: 1em;
    top: 0.8em;
    opacity: 0;
    transition: all 330ms ease-out;
  }

  &:hover {
    background-color: #f5f5f2;
    color: var(--btn-primary);

    &:after {
      opacity: 1;
    }
  }

  &.is-active {
    background-color: #f5f5f2;
    color: var(--btn-primary);
  }
}
