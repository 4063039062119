.c-works {
  padding-bottom: 1em;
}

.c-works__image {
  position: relative;
  
  .t-list & {
    margin: 0 !important;
    min-width: 220px;
    padding: 0 10px;
    width: 220px;
    
    .is-loaded {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      
      span {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        
        img {
          height: auto;
          max-height: 220px;
          max-width: 100%;
          width: auto;
        }
      }
    }
    
    @include mq(tablet) {
      position: unset;
      .is-loaded {
        span {
          max-height: 100%;
          
          img {
            height: auto;
            max-height: 220px;
            max-width: 100%;
            width: auto;
          }
        }
      }
    }
  }
}

.c-works__title,
.c-works__title h3 {
  -webkit-box-orient: vertical;
  @include font-size(16px);
  cursor: pointer;
  display: -webkit-box;
  font-family: $spectral;
  font-weight: normal;
  height: 60px;
  -webkit-line-clamp: 2;
  margin: 0 0 0.5em;
  overflow: hidden;
  transition: 0.2s linear 0s;
  @include mq($until: mobileLandscape) {
    height: 50px;
  }
  
  &:hover {
    color: var(--btn-primary-hover);
  }
  
  .t-list & {
    @include font-size(20px);
    font-weight: 300;
    height: auto;
    margin: 0 0 0.5em;
    @include mq(tablet) {
      -webkit-line-clamp: 3;
      @include font-size(24px);
      margin: 0 0 1.043478260869565em;
    }
  }
  
  @include mq(mobileLandscape) {
    @include font-size(20px);
    margin: 0 0 0.695652173913043em;
  }
}

.c-works__note {
  @include font-size(14px);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-weight: normal;
  -webkit-line-clamp: 3;
  margin: 0 0 0.5em;
  overflow: hidden;
  padding-right: 3em;
  
  .t-list & {
    @include font-size(14px);
    margin: 0 0 1.142857142857143em;
    @include mq(tablet) {
      @include font-size(16px);
      margin: 0 0 1em;
    }
  }
}

.cleave {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
  width: 100%;
  
  label {
    margin-right: 10px;
  }
}

.item-detail-row {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 2px 0;
  width: 100%;
  
  > div {
    align-self: center;
  }
  
  span.location {
    flex-grow: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  a.c-works__location {
    margin: 0;
  }
  
  .item-sublocation {
    margin-left: 10px;
  }
  
  .item-dimensions {
    font-family: $spectral;
    width: 100%;
  }
  
  .item-inventory-number {
    margin-left: auto;
  }
  
  .item-value {
    @include font-size(14px);
    color: var(--text-secondary);
    padding-left: 2px;
  }
  
  &.list {
    flex-direction: column;
    
    .c-works__location,
    .item-inventory-number {
      align-self: flex-start !important;
    }
    
    .c-works__location, {
      margin-bottom: 5px;
    }
    .item-inventory-number {}
  }
  
  &.cta {
    align-items: center;
    border-top: 1px solid $gray-300;
    justify-content: flex-end;
    margin: 0;
    padding: 15px 0;
    
    .checkbox-wrap {
      height: 30px;
    }
    
    &.asking-price {
      justify-content: space-between;
    }
  }
  
  .c-works__cta & {
    justify-content: space-between;
    padding: 10px 0;
    
    &.cta {
      border-top: 0;
      
      &.asking-price {
        border-bottom: 1px solid $gray-300;
        margin-bottom: 10px;
      }
      
      &:last-child {
        padding-bottom: 0;
        
        .cleave {
          margin-bottom: 0;
        }
      }
    }
  }
}

.c-works__location {
  @include font-size(14px);
  align-items: center;
  color: var(--text-secondary);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  justify-self: flex-start;
  margin-bottom: 0;
  max-width: calc(100% - 82px);
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition: color 0.2s;
  white-space: nowrap;
  
  &:hover {
    color: var(--text-primary);
    transition: color 0.2s;
  }
  
  i {
    font-size: 18px;
    transform: translateY(-1px);
  }
  
  span {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.item-inventory-number {
  @include font-size(14px);
  align-items: center;
  color: var(--text-secondary);
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  
  i {
    font-size: 18px;
    transform: translateY(-1px);
  }
  
  .t-list & {
    margin-left: 0;
  }
}

.c-works--list {
  margin-bottom: 3.75em;
  
  .t-grid & {
    @include mq(tablet) {
      justify-content: flex-start;
      margin: 0;
    }
  }
}

.c-link--send {
  align-items: center;
  background-color: var(--text-primary);
  color: var(--text-white);
  display: none;
  height: 35px;
  justify-content: center;
  padding: 0 1em;
  white-space: nowrap;
  width: 123px;
  
  .t-list & {
    display: none;
    @include mq(tablet) {
      display: flex;
    }
  }
}

.c-load-more {
  display: flex;
  justify-content: center;
}

.c-link--more {
  @include font-size(14px);
  align-items: center;
  border: 1px solid var(--border-color);
  display: flex;
  font-weight: 500;
  height: 51px;
  justify-content: center;
  padding: 0 1.714285714285714em;
  text-transform: uppercase;
  width: 100%;
  
  svg {
    margin-left: 1.714285714285714em;
  }
  
  span {
    align-items: center;
    display: flex;
  }
  
  &:hover {
    border-color: var(--border-color-active);
  }
  
  .is-loading {
    display: none;
    
    svg {
      margin: 0;
    }
  }
  
  &.is-active {
    border-color: var(--border-color-active);
    
    span {
      display: none;
    }
    
    .is-loading {
      display: flex;
    }
  }
  
  @include mq(tablet) {
    width: 171px;
  }
}

.c-works__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 270px;
  margin-bottom: 1em;
  width: 100%;
  
  .t-list & {
    height: 105px;
    margin-bottom: 0;
    @include mq(tablet) {
      height: 216px;
    }
  }
  
  .t-grid & {
    cursor: pointer;
  }
}

.c-works__details__cta {
  align-items: center;
  border-top: 1px solid $gray-300;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.c-works__cta__col {
  align-items: center;
  display: flex;
  flex-grow: 1;
  width: 100%;
  
  @include mq(desktop) {
    width: auto;
    &:first-child {
      display: flex;
    }
  }
}

.c-works__photo {
  .t-list & {
    height: 105px;
    object-fit: contain;
    object-position: top;
    width: 100%;
    @include mq(tablet) {
      height: 216px;
    }
  }
  
  .t-grid & {
    height: 270px;
    object-fit: contain;
    width: 100%;
  }
}

.c-works__image {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-bottom: 1em;
  
  &.is-loaded {
    align-self: flex-start;
    background: transparent;
    
    .c-works__photo {
      opacity: 1;
    }
  }
}
