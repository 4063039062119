.c-lists {
  display: flex;
  height: auto;
  justify-content: space-between;
  overflow-x: hidden;
  position: relative;
}

.c-loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 390px);
  justify-content: center;
  width: 100%;

  @include mq($from: desktop, $until: superwide) {
    align-self: flex-end;
    margin-left: 25%;
    width: 75%;
  }
}

.c-loading--header {
  align-items: center;
  display: flex;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  svg {
    margin-right: 1em;
  }

  @include font-size(12.8px);
}

.sidebar-nav {
  background-color: $white;
  left: 15px;
  max-height: calc(100vh - 130px);
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 0;
  transition: top 0.2s ease;
  -webkit-overflow-scrolling: touch;

  .c-filter__wrap {
    max-height: calc(100vh - 310px);
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 5px 300px 0;
    position: relative;
    scrollbar-color: rgba($gold1, 0.5) white;
    scrollbar-width: thin;

    .c-filter--submit {
      background-color: var(--bg-secondary);
      margin: 0 10vw 30px;
      position: fixed;
    }
  }

  .search-input {
    width: 100%;
  }

  .sidebar-hidden & {
    overflow: hidden;
    padding: 0;
    transition: width 0.1s;
    width: 0;
  }

  @include mq($from: desktop) {
    bottom: 0;
    left: 0;
    opacity: 1;
    padding-left: 15px;
    position: fixed;
    top: 140px;
    transform: unset;
    transition: top 0.2s ease;
    width: 354px;
    z-index: 1;
  }

  @include mq($until: desktop) {
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    display: none;
    left: 15px;
    max-height: calc(100vh - 60px);
    overflow: hidden;
    padding: 0 0.5rem;
    right: 15px;
    top: 80px;

    .c-filter {
      max-height: calc(100vh - 60px);
      padding: 0;
    }

    .c-filter__header {
      width: 100%;
    }

    .c-filter__wrap {
      max-height: calc(100vh - 420px);
      padding-right: 10px;
    }

    .is-fixed & {
      display: block;
      opacity: 1;
      z-index: 15;
    }
  }
}

.c-link--close {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.c-link {
  color: var(--text-primary);
  cursor: pointer;
  text-decoration: none !important;
  transition: all 0.2s linear 0s;

  .c-navigation--header & {
    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom-color: black;
      transition: all 0.2s linear 0s;
    }
  }

  @include font-size(14px);
}

.c-link--logo {
  align-items: center;
  display: flex;

  @include mq($until: mobileLandscape) {
    svg {
      max-width: 80px;
    }
  }
}

.c-link--location-text {
  align-items: center;
  background-color: var(--bg);
  border-radius: 2px;
  border: 1px solid var(--border-color);
  display: flex;
  font-weight: 500;
  height: 85px;
  justify-content: center;
  letter-spacing: 0.05em;
  overflow: hidden;
  padding: 1.5em;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    &:after {
      transform: translateY(0);
    }
  }

  @include font-size(14px);

  @include mq(tablet) {
    @include font-size(16px);
  }
}

.c-link--location {
  align-items: center;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.05em;
  overflow: hidden;
  padding: 1.2em;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  > span {
    display: block;
    max-width: 250px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
  }

  &:hover {
    &:after {
      transform: translateY(0);
    }
  }

  @include font-size(22px);

  @include mq(tablet) {
    @include font-size(22px);
  }
}

.c-link--section {
  bottom: 3em;
  position: absolute;

  &::before {
    background-color: var(--text-primary);
    bottom: 0;
    content: "";
    display: block;
    height: 1.5px;
    position: absolute;
    width: 100%;
  }

  @include mq(tablet) {
    bottom: auto;
    margin-left: 1.5em;
    position: relative;
  }
}

.c-link--categories {
  align-self: center;
  border: none;
  color: var(--text-primary);
  justify-content: center;
  letter-spacing: unset;
  text-align: left;
  text-transform: capitalize;

  span {
    text-shadow: none;
  }

  @extend .c-link--location;
  @include font-size(19px);
}

.c-link--bin {
  align-items: center;
  background-color: var(--bg);
  border-radius: 2px;
  border: 1px solid var(--border-color);
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 40px;
  width: 40px;

  svg {
    path {
      transition: 0.2s linear 0s;
    }
  }

  &:hover {
    border-color: var(--status-damaged);

    svg {
      path {
        fill: var(--status-damaged);
      }
    }
  }

  &.btn-delete-all {
    width: auto;

    span {
      margin-right: 15px;
      text-transform: uppercase;
    }
  }
}

.c-link--title {
  font-weight: normal;
  margin: 0 0 1.043478260869565em;

  &:hover {
    color: var(--btn-primary-hover);
  }

  @include font-size(17px, 1);

  @include mq(desktopAd) {
    @include font-size(20px, 1);
  }
}
