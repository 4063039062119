.c-details__col {
  padding: 0 15px;
  
  &:last-child {
    @extend .c-modal__item__text;
  }
  
  &.slider {
    text-align: center;
    position: relative;
  }
  
  @include mq($from: superwide) {
    &.slider {
      width: 50%;
    }
    &.info {
      width: 50%;
    }
  }
  @include mq($from: wide, $until: superwide) {
    &.slider {
      width: 45%;
    }
    &.info {
      width: 55%;
    }
  }
  @include mq($from: desktopAd, $until: wide) {
    &.slider {
      width: 35%;
    }
    &.info {
      width: 65%;
    }
  }
  @include mq($until: desktopAd) {
    &.slider {
      width: 100%;
      padding: 0;
    }
    &.info {
      padding: 0;
      width: 100%;
      margin-top: 20px;
    }
  }
}

.model-viewer {
  position: relative;
  width: 100%;
  text-align: center;
  height: auto;
  
  model-viewer {
    --progress-bar-color: rgba(196, 172, 126, 1);
    height: 480px;
    margin: 0 auto;
    width: 480px;
    
    div.container:focus {
      outline: none !important;
    }
  }
  
  footer.controls {
    text-align: center;
    font-size: 18px;
  }
  
  @include mq($until: mobileLandscape) {
    model-viewer {
      width: 80%;
      margin: 0 auto;
      height: 260px;
      
      div.container:focus {
        outline: none !important;
      }
    }
    footer.controls {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      font-size: 12px;
      width: 100%;
    }
  }
}

.item-detail-nav {
  align-items: center;
  background-color: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 60px;
  padding: 0;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 70px;
  z-index: 4;
  
  > button {
    padding: 2px 10px;
    cursor: pointer;
    transition: color 0.2s;
    appearance: none;
    background: none;
    border: 0;
    outline: none;
    
    &.back-to-list {
      font-size: 12px;
      line-height: 12px;

    }
    
    i.material-icons {
      font-size: 18px;
    }
    
    &:hover {
      transition: color 0.2s;
      color: $gold;
    }
    
    &.prev-item,
    &.next-item {
      .material-design-icon {
        pointer-events: none;
    
        svg {
          width: 18px;
      
        }
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        
      }
    }
  }
}
