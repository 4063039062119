#cart {
  overflow: hidden;

  section.o-section.c-section--cart.js-content {
    padding: 0 !important;

    .o-section__wrapper {
      padding: 0;
    }
  }
}

.c-section--cart {
  padding-top: 30px !important;

  .o-section__wrapper {
    padding: 0;

    @include mq(tablet) {
      padding: 0 15px;
    }
  }
}

/*

    TRANSFER CART MODAL


*/
.modal-backdrop.transfer-modal {
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.confirm-transfer-modal {
  background-color: $white;
  height: calc(100vh - 110px);
  margin: auto;
  overflow: auto;
  padding-bottom: 150px;
  width: calc(100vw - 100px);
  -webkit-overflow-scrolling: touch;

  header.modal-header {
    background-color: $dark;
    border-bottom: 1px solid $gold6;
    color: $gold6;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 10px 0;
    position: fixed;
    width: calc(100vw - 100px);
    z-index: 9;

    h2 {
      align-self: center;
      margin: 0;
    }
  }

  .modal-body {
    justify-content: flex-start;
  }

  header.transfer-list-header {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: flex-start;
    margin-top: 90px;
    padding: 0 35px;
    width: 100%;

    span {
      font-family: $spectral;
      font-size: 18px;
      font-weight: normal;
      text-transform: capitalize;

      &.item-name {
        width: 60%;
      }

      &.item-arrow {
        min-height: 1px;
        width: 40px;
      }

      &.item-destination {
        padding-left: 15px;
      }

      &.need-by {
        margin-left: auto;
        width: 120px;
      }
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  ol.transfer-list {
    counter-reset: list-counter;
    margin: 0;
    padding: 0;

    > li {
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      counter-increment: list-counter;
      display: flex;
      justify-content: flex-start;
      padding: 10px 35px 15px 35px;
      position: relative;

      &::before {
        background-color: $gold7;
        border-bottom-right-radius: 3px;
        color: $black;
        content: counter(list-counter) "";
        font-size: 11px;
        font-weight: bold;
        left: 0;
        padding: 1px 5px 3px 5px;
        position: absolute;
        top: 0;
      }

      &:nth-child(odd) {
        background-color: $gold8;
      }

      &:nth-child(even) {
        background-color: #fdfdfd;
      }

      ul.items {
        margin: 0;
        min-width: 60%;
        padding: 0;
        width: 60%;

        li.item {
          align-items: flex-start;
          border-bottom: 1px dashed #cccccc;
          display: flex;
          flex-direction: column;
          padding: 5px 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
          }

          .item-artist {
            font-size: 13px;
            font-weight: bold;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .item-title {
            font-size: 15px;
            font-weight: bold;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .item-id {
            color: $medium-gray;
            font-size: 12px;
            letter-spacing: 0.04em;
          }

          .item-note {
            margin-top: 10px;
            width: 100%;

            label {
              display: block;
              font-size: 10px;
            }

            input {
              font-size: 12px;
              height: auto;
              padding: 0.5em 0.75em;
              width: 100%;
              @extend .c-form__input;
            }
          }
        }

        li.item:not(:last-child) {
          .item-note {
            padding-bottom: 10px;
          }
        }
      }

      .item-destination {
        align-items: center;
        display: flex;
        padding-left: 15px;
        width: 20%;

        .item-arrow {
          color: $gold;
          font-size: 32px;
          line-height: 16px;
          margin-right: 10px;
          text-align: center;
          width: 40px;
        }

        .item-location {
          align-items: center;
          display: flex;
          font-weight: bold;
          width: 20%;
        }

        @include mq($from: tablet, $until: desktop) {
          span.location {
            max-width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .need-by {
        margin-left: auto;
        width: 120px;

        > div {
          input.c-dropdown__title {
            max-width: 100%;
            width: auto;
          }
        }
      }
    }

    @include mq($until: tablet) {
      margin-top: 60px;

      > li {
        flex-direction: column;
        padding: 15px 5px;

        ul.items {
          width: 100%;

          li.item {
            align-items: center;
          }
        }

        .arrow {
          height: 40px;
          margin-bottom: 15px;
          visibility: hidden;

          &::after {
            content: "\2193";
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            visibility: visible;
          }
        }

        .item-destination {
          justify-content: center;
          margin: 8px 0 10px;
          padding: 0;
          width: 100%;
        }

        .need-by {
          margin: auto;
          text-align: center;
          width: initial;

          > div {
            input.c-dropdown__title {
              max-width: 100%;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }
  }

  footer.modal-footer {
    width: 100%;

    .enter-email {
      padding: 25px 0;

      .checkbox-wrap {
        display: flex;
        height: auto;
        justify-content: center;
        margin-bottom: 15px;
        width: 100%;

        .form-group {
          display: flex;
          margin: 0;
          position: relative;
          width: auto;

          input.squarecheck + label:before {
            height: 21px;
            left: 0;
            top: 0;
            width: 21px;
          }

          input.squarecheck + label:after {
            display: none;
          }

          input.squarecheck:checked + label:after {
            display: block;
            height: 13px;
            left: 4px;
            top: -1px;
            width: 13px;
          }

          label {
            align-self: center;
          }
        }
      }

      .email-wrap {
        display: flex;
        justify-content: center;

        .form-group {
          display: flex;
          justify-content: center;
          position: relative;
          width: 100%;

          input#managerEmail {
            border: 1px solid $dark;
            width: 256px;

            &:disabled {
              border: 1px solid var(--border-color);
            }
          }

          label {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 15px;
            text-align: center;
          }
        }
      }
    }

    .btn-toolbar {
      border-top: 1px solid var(--border-color);
      display: flex;
      justify-content: flex-end;
      padding: 20px 35px;
      width: 100%;

      .c-btn {
        margin: 0 5px;
        white-space: nowrap;
        width: auto;
      }
    }

    &.buy {
      display: block;

      .cart-footer-row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        padding-right: 152px;
        width: 100%;
      }

      label.total {
        margin-left: 40px !important;
        @extend .cart-header;
      }
    }
  }

  @include mq($until: tablet) {
    height: calc(100vh - 30px);
    width: calc(100vw - 30px);

    header.modal-header {
      width: calc(100vw - 30px);
    }

    footer.modal-footer {
      .enter-email {
        .checkbox-wrap {
          .form-group {
            max-width: initial;

            label {
              margin: -4px 0 0 40px;
            }
          }
        }
      }

      .btn-toolbar {
        justify-content: stretch;
        padding: 15px;

        button {
          flex-grow: 1;
        }
      }

      .c-btn,
      input {
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
}

/*

    CART


*/
.scanner-wrap {
  margin-bottom: 2rem;
  text-align: center;

  .scan-item {
    margin-bottom: 15px;
  }

  p {
    margin: 0;
  }
}

.cart-wrap {
  padding: 0 0 10px;

  header.cart-header {
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-primary);
    display: none;
    font-family: $spectral;
    font-weight: 300;
    justify-content: flex-start;
    padding: 0 20px;

    span {
      &.item {
        margin-left: 118px;
      }

      &.asking {
        margin-left: auto;
      }

      &.destination {
        margin-left: auto;
        text-align: right;
        width: 180px;
      }

      &.delete {
        margin-left: 15px;
        text-align: center;
        width: 40px;
      }

      @extend .cart-header;
    }

    @include font-size(20px);

    @include mq(tablet) {
      display: flex;
    }
  }

  .cart-list {
    .cart-item {
      align-items: flex-start;
      border-bottom: 1px solid var(--border-color);
      color: inherit;
      display: flex;
      padding: 20px;
      position: relative;
      width: 100%;

      &:nth-child(2n) {
        background-color: var(--bg-secondary);
      }

      &:hover {
        .delete-item {
          opacity: 1;
        }
      }

      &.Cancelled {
        display: none;
      }

      .item-link {
        align-items: center;
        color: inherit;
        display: flex;
        justify-content: flex-start;
        padding-right: 30px;
        text-decoration: none;
        transition: color .2s;

        &:hover {
          color: $gold;
          transition: color .2s;
        }
      }

      .cart-thumb {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 80px;
        width: 80px;

        @include mq(tablet) {
          height: 100px;
          width: 100px;
        }
      }

      .details {
        align-self: center;
        margin-left: 15px;

        .title {
          font-family: $spectral;
          font-weight: normal;
          margin: 0;
          @include font-size(23px);

          @include mq($until: tablet) {
            margin: 10px 0;
            text-align: center;
            @include font-size(18px);
          }
        }
      }

      .asking-price {
        align-self: center;
        margin-left: auto;
      }

      .current-location {
        align-items: center;
        align-self: center;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        max-width: 100%;
        overflow: hidden;
        padding: 0 !important;
        text-overflow: ellipsis;

        .material-icons {
          color: $gold;
          font-size: 18px;
        }

        @extend .c-works__location;
        @include font-size(14px);

        @include mq($until: tablet) {
          align-self: flex-start;
          justify-content: center;
          transform: translateX(-15px);
        }
      }

      .delete-item {
        align-self: center;
        margin-left: 15px;
        opacity: 1;

        @include mq(tablet) {
          opacity: 0;
        }

        @include mq($until: tablet) {
          margin: 15px 0 0 0;
        }
      }

      .locations-dropdown {
        align-self: center;
        font-family: $sans;
        margin-left: auto;
        position: relative;

        .c-dropdown {
          position: relative;
          width: 180px;

          .c-dropdown__title {
            span.c-dropdown__text {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          ul.c-dropdown__list {
            max-height: 300px;
            min-width: 130px;
            overflow-y: scroll !important;
            padding-bottom: 60px;
            right: 0;
            width: fit-content;

            li.c-dropdown__list__items {
              a.c-link--dropdown {
                white-space: nowrap;
              }
            }

            &.dropup {
              bottom: 41px;
            }
          }

          @include mq($until: tablet) {
            margin: 15px 0 0 !important;
          }
        }

        @extend .c-modal__item__text;

        @include mq($until: tablet) {
          display: flex;
          justify-content: center;
          margin: 0;
          width: 100%;
        }
      }
    }
  }

  footer.cart-footer {
    padding: 20px 20px 15px;
    width: 100%;

    .cart-footer-row {
      align-items: stretch;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      width: 100%;

      button {
        height: 40px;
        width: auto;
      }

      .c-btn &.total {
        align-items: center;
        justify-content: space-between;

        label.total {
          color: var(--text-primary);
          font-family: $spectral;
          margin: 0 10px 0 118px;
          @include font-size(20px);
        }

        .cart-total {
          margin-right: 55px;
        }
      }

      &.submit {
        margin-top: 25px;

        button.c-btn {
          margin-right: 55px;
          width: auto;
        }
      }

      .btn-wrap {
        flex-grow: 1;

        button.c-btn {
          width: auto;
        }

        .btn-reset-locations {
          margin-left: auto;
          margin-right: 15px;
        }
      }
    }
  }

  @include mq($until: tablet) {
    padding-bottom: 15px;

    .cart-list {
      .cart-item {
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;

        .asking-price {
          font-size: 18px;
          margin: 0;
        }
      }
    }

    footer.cart-footer {
      padding: 15px 10px;

      .cart-footer-row {
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0;

        &.total {
          justify-content: center;
          margin-bottom: 15px;

          span.cart-total,
          label.total {
            margin: 0;
          }
        }

        &.submit {
          justify-content: center;

          button.c-btn {
            margin: 0;
          }
        }

        .checkbox-wrap {
          margin-bottom: 15px;
        }

        .btn-wrap {
          display: flex;
          margin-bottom: 15px;
          width: 100%;

          button.c-btn {
            flex-grow: 1;
            margin: 0;
            max-width: 250px;
            min-width: 150px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.c-btn--cart {
  &:disabled {
    background-color: var(--btn-disabled);
    cursor: not-allowed;
  }
}

.c-dropdown__locations {
  margin-right: 15px;

  &.is-disabled {
    .c-dropdown__title {
      color: var(--btn-disabled);
      cursor: not-allowed;
    }
  }

  ul.c-dropdown__list {
    min-width: 230px;
    right: 0;
    width: fit-content;

    li.c-dropdown__list__items {
      a.c-link--dropdown {
        white-space: nowrap;
      }
    }
  }
}

.c-cart__sendto {
  align-items: center;
  display: flex;
}

.c-cart__sendto__col {
  &:first-child {
    color: var(--text-primary);
    display: none;
    font-weight: 500;
    margin-right: 0.5em;
    @include font-size(17px);

    @include mq(desktop) {
      display: flex;
    }
  }
}

::placeholder {
  color: var(--text-primary);
}

.previous-list {
  .cart-item-wrap {
    border-top: 1px solid $border-color;
    padding: 15px 0;

    &:nth-child(even) {
      background-color: var(--bg-secondary);
    }

    .previous-details {
      align-items: flex-start;
      align-self: stretch;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 10px;
      width: 100%;

      .detail {
        display: flex;
        margin-bottom: 5px;
        width: 100%;

        i {
          align-self: center;
          font-size: 15px;
          margin-right: 2px;
        }

        span.location {
          align-items: center;
          align-self: center;
          background-color: $gold7;
          border-radius: 3px;
          font-size: 13px;
          font-weight: normal;
          margin-left: 5px;
          padding: 1px 6px 0 !important;
        }

        span.date {
          margin-left: auto;
        }

        span.small-progress {
          line-height: 0;

          svg {
            width: 25px;
          }
        }

        &.status {
          span {
            align-items: center;
            align-self: center;
            border-radius: 3px;
            font-size: 13px;
            font-weight: normal;
            margin-left: 5px;
            padding: 1px 6px 0 !important;

            &.Submitted {
              background-color: #0b76b7;
              color: $white;
            }

            &.Received {
              background-color: #06a09b;
              color: $white;
            }

            &.In-progress {
              background-color: #b87503;
              color: $dark;
            }

            &.Processed {
              background-color: #338a17;
              color: $white;
            }

            &.Cancelled {
              background-color: #ffeab6;
              color: $dark;
            }

            &.Archived {
              background-color: #fee2d5;
              color: $dark;
            }

            &.Deleted {
              background-color: #444444;
              color: $white;
            }

            &.Needs-Review {
              background-color: #ffdce5;
              color: $dark;
            }

            &.Complete {
              background-color: #d1f7c4;
              color: $dark;
            }
          }
        }

        @include mq($until: tablet) {
          align-self: center;
        }
      }

      label {
        min-width: 120px;
      }

      ul {
        list-style: square;
        margin: 0;
        padding: 0 0 0 20px;

        li {
          a {
            &:hover {
              text-decoration: underline !important;
            }

            @extend .c-link;
          }
        }
      }
    }

    &.Processed {
      .transfer-details {
        .detail.status {
          span {
            align-items: center;
            align-self: center;
            border-radius: 3px;
            font-size: 13px;
            font-weight: normal;
            margin-left: 5px;
            padding: 1px 6px 0 !important;
          }
        }
      }
    }
  }
}
