.c-btn {
  @include font-size(14px);
  align-items: center;
  background-color: $dark;
  border: 1px solid $dark;
  border-radius: 2px;
  color: var(--text-white);
  cursor: pointer;
  display: flex;
  font-family: $sans;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  letter-spacing: 0.1em;
  margin-right: 10px;
  max-width: calc(50% - 5px);
  padding: 0.25em 1.2em;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
  width: calc(50% - 5px);
  
  &.remove {
    background-color: $danger;
    border-color: $danger;
    
    &:hover {
      background-color: $danger-hover;
      border-color: $danger-hover;
    }
  
}
  &:last-child {
    margin-right: 0;
  }
  
  &::after {
    display: none;
  }
  
  &:hover {
    background-color: $gold;
    border-color: $gold;
    color: $white;
    transition: all 0.2s;
    
    svg {
      transition: all 0.2s;
    }
  }
  
  &:active,
  &:focus {
    outline: none;
  }
  
  &.sidebar-toggle {
    background-color: $white;
    border-color: $gray-500;
    color: $dark;
    max-width: initial;
    width: initial;
    
    span.arrow {
      margin-right: 4px;
      transform: translateY(-1px);
    }
    
    @include mq($until: desktop) {
      display: none;
    }
  }
  
  &.disabled {
    background-color: $gray-700;
    border-color: $gray-700;
    pointer-events: none;
  }
  
  // clear filters
  &.btn-clear-filters {
    background-color: transparent;
    border: 0;
    color: var(--text-secondary);
    font-weight: normal;
    letter-spacing: normal;
    margin-right: 5px;
    padding: 10px 0 0 0;
    position: relative;
    text-transform: capitalize;
    width: auto;
    
    &:hover,
    &:active {
      background-color: transparent !important;
      color: $black;
    }
    
    &::after {
      all: initial;
    }
    
    @include mq($until: tablet) {
      border: 1px solid $gray-600;
      font-size: 12px;
      height: initial;
      padding: .25rem 1rem;
      text-transform: uppercase;
      
      &:hover {
        border-color: $red;
        color: $red
      }
    }
  }
  
  &.btn-sort {
    background-color: $gold;
    border-color: $gold;
    color: $white;
    margin: 15px 15px 0 0;
    max-width: 100%;
    white-space: nowrap;
    width: 100%;
    
    &:hover {
      background-color: $gold2;
      border-color: $gold2;
      color: $white;
    }
  }
  
  // submit filters
  &.btn-submit-filters {
    max-width: 100%;
    width: 100%;
    
    &.no-filters {
      background-color: $white;
      border-color: $dark;
      color: $dark;
      
      &:hover {
        background-color: $gray-200;
        color: $black;
      }
    }
  }
  
  // btn view details
  &.btn-view-details {
    background-color: $white;
    color: $dark;
    white-space: nowrap;
    
    &:hover {
      background-color: $gold;
      border-color: $gold;
      color: $white;
    }
    
    &.w-auto {
      width: auto;
    }
  }
  
  // favorite
  &.btn-favorite {
    background-color: $white;
    color: $dark;
    
    &:hover {
      background-color: $gold;
      border-color: $gold;
      color: $white;
      
      .icon {
        svg {
          fill: $white;
        }
      }
    }
    
    .icon {
      margin: 0;
      
      svg {
        fill: $dark;
        height: 12px;
        width: 14px;
      }
    }
    
    span.btn-text {
    }
    
    .c-modal__buttons & {
      flex-grow: 1;
      margin: 0;
    }
    
    &.active {
      background-color: $gold;
      border-color: $gold;
      color: $white;
      
      .icon {
        color: $white;
        
        svg {
          fill: $white;
          height: 12px;
          width: 14px;
        }
      }
      
      &:hover {
        background-color: $white;
        border-color: $dark;
        color: $dark;
        
        .icon {
          color: $dark;
          
          svg {
            fill: $dark;
          }
        }
      }
    }
  }
  
  // set asking
  &.set-asking-price {
    font-size: 13px;
    height: 30px;
    line-height: 14px;
    margin: 0 0 0 8px;
    max-width: 50px;
    padding: 0 8px;
    
  }
  
  &.success {
    background-color: $success;
    border-color: $success;
    
    &:hover {
      background-color: darken($success, 5%);
      border-color: darken($success, 5%);
    }
  }
  
  // add cart
  &.btn-add-cart {
    align-self: center;
    margin: 0 0 0 10px;
    
    .c-modal__buttons & {
      margin: 0 10px 0 0;
    }
    
    > span {
      display: block !important;
    }
    
    svg {
      margin-right: 12px;
      max-height: 20px;
      min-width: 20px;
      
      path {
        fill: $white;
      }
    }
    
    // transfer
    &.transfer {
      margin-left: 0;
      
      &.is-active {
        background-color: $danger;
        border-color: $danger;
        
        &:hover {
          background-color: $danger-hover;
          border-color: $danger-hover;
        }
      }
    }
    
    // buy
    &.buy {
      background-color: $success;
      border-color: $success;
      
      &:hover {
        background-color: $success-hover;
        border-color: $success-hover;
      }
      
      &.is-active {
        background-color: $danger;
        border-color: $danger;
        
        &:hover {
          background-color: $danger-hover;
          border-color: $danger-hover;
        }
      }
    }
  }
  
  &.util {
    padding: 5px 25px;
    width: auto !important;
  }
  
  span.icon {
    display: flex;
    justify-content: center;
    line-height: 0;
    margin-right: 5px;
    width: 22px;
    
    svg {
      fill: $white;
      transition: all 0.2s;
      width: 14px;
    }
  }
  
  .is-loading {
    width: 22px;
    
    svg {
      width: 100%;
    }
  }
  
  &.btn-cancel {
    background-color: $red;
    border-color: $red;
    max-width: 100%;
    width: 100%;
    
    &:hover {
      background-color: $red-hover;
      border-color: $red-hover;
    }
  }
  
  &.btn-reset-locations {
    background-color: $red;
    border-color: $red;
    
    &:hover {
      background-color: $red-hover;
      border-color: $red-hover;
    }
  }
  
  &.btn-open-save-modal {
    height: 30px;
    padding: 4px 22px;
    white-space: nowrap;
    width: auto !important;
  }
  
  &.btn-save-pdf {
    background-color: $success;
    border-color: $success;
    font-size: 16px;
    font-weight: 300;
    height: 47px;
    width: 120px;
  }
  
  &.btn-remove {
    @include font-size(11px);
    align-items: center;
    align-self: flex-start;
    background-color: transparent;
    border: 1px solid $gold1;
    color: $gold1;
    display: flex;
    flex-grow: 1;
    height: auto;
    justify-content: center;
    margin: 0 5px 6px 3px;
    max-width: 50%;
    min-width: initial;
    overflow: hidden;
    padding: 0 2px;
    position: relative;
    transition: all 0.2s linear;
    width: auto;
    
    &:hover {
      background-color: $gold1;
      color: $white;
      transition: all 0.2s linear;
    }
    
    span {
      @include font-size(11px);
      align-self: center;
      
      &.text {
        flex-grow: initial;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      &.material-design-icon.close-icon {
        position: absolute;
        right: 2px;
        top: 2px;
        width: auto;
        
        svg {
          height: 13px;
          width: 13px;
        }
      }
    }
  }
  
  .layout-wrap &,
  .slick-slider-wrap & {
    &.c-btn--cart {
      height: 34px;
      padding: 0;
      width: 34px;
    }
  }
  
  &.c-btn--invoice {
    align-items: center;
    background-color: var(--bg-purple);
    border: 1px solid var(--border-color-purple);
    color: var(--text-primary);
    display: flex;
    font-weight: 500;
    height: 32px;
    justify-content: center;
    letter-spacing: normal;
    margin: 0;
    max-width: initial;
    min-width: 130px;
    padding: 0 1.15em;
    width: auto;
    
    &:hover {
      background-color: darken(#ece6ff, 5%);
      color: var(--text-primary);
    }
    
    &:disabled {
      span {
        display: none;
      }
    }
  }
  
  @include mq($until: desktopAd) {
    @include font-size(12px);
    height: 30px;
  }
  
  @include mq($until: mobileLandscape) {
  }
}

/*

    OTHER BUTTONS


*/
.btn-white {
  align-self: center;
  background-color: $white;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  color: var(--text-primary);
  display: inline-block;
  font-size: 13px;
  padding: 3px 10px;
  text-transform: uppercase;
  transition: all 0.2s;
  
  &:hover {
    background-color: $gold;
    border-color: $gold;
    color: $white;
    transition: all 0.2s;
  }
}

.btn-link {
  color: $gold;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
  
  &:hover {
    color: $gold4;
    transition: all 0.2s;
  }
}

.btn-black {
  align-self: center;
  background-color: $black;
  border-radius: 3px;
  color: $white;
  display: inline-block;
  font-size: 13px;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
}

.c-btn.c-btn--filter.sort {
  background-color: transparent;
  border: 1px solid $light-gray;
  color: $medium-gray;
  font-size: 12px;
  height: auto;
  margin-left: 8px;
  padding: 2px 20px;
  
  &.active {
    background-color: $gold;
    border-color: $gold;
    color: $white;
    
    svg {
      path {
        stroke: $white;
      }
    }
  }
}

.c-btn--close {
  background: var(--bg);
  border: 1px solid #dfdcd4;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0.844em;
  transition: 0.2s linear 0s;
  z-index: 2;
  
  svg {
    path {
      transition: 0.2s linear 0s;
    }
  }
  
  &:hover {
    border-color: var(--text-primary);
    
    svg {
      path {
        stroke: var(--text-primary);
      }
    }
  }
  
  &:focus {
    border-color: var(--text-primary);
  }
  
  @include mq(tablet) {
    top: 8px;
  }
}

.btn-close-modal {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 1px solid $gold4;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 34px;
  justify-content: center;
  outline: none;
  padding: 10px;
  position: absolute;
  right: 8px;
  top: 8px;
  transition: border-color 0.2s;
  width: 34px;
  
  svg {
    fill: $gold5;
    transition: fill 0.2s;
  }
  
  &:hover {
    border-color: $gold5;
    transition: border-color 0.2s;
    
    svg {
      fill: $gold7;
      transition: fill 0.2s;
    }
  }
}

.c-btn--more {
  padding: 0.572em 1.763em;
  
  svg {
    margin-left: 1.1em;
  }
}

.c-btn.c-btn--filter.js-mobile {
  padding-left: 17px;
  padding-right: 17px;
  white-space: nowrap;
  
  @include mq($until: mobileLandscape) {
    margin: 0 0 10px;
    width: 100%;
  }
}

.c-btn--modal {
  align-items: center;
  display: flex;
  font-weight: 500;
  height: 30px;
  @include font-size(12px);
  justify-content: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  width: 86px;
  
  @include mq(desktop) {
    display: none;
  }
}

.c-btn.c-btn--confirm,
.c-btn.btn-success {
  background-color: $success;
  border: 1px solid $success;
  transition: all 0.2s;
  
  &:hover {
    background-color: $success-hover;
    border-color: $success-hover;
    color: $white;
    transition: all 0.2s;
  }
  
  &:disabled {
    background-color: $success !important;
    opacity: 0.3;
    
    span {
      display: none;
    }
  }
}

.btn-cancel {
  background-color: $dark;
  border: 1px solid $dark;
  color: $white;
  
  &:hover {
    background-color: $red;
    border: 1px solid $red;
    color: $white;
  }
}

.is-hidden {
  display: none;
}

.is-loading {
  align-items: center;
  display: flex;
}

.c-btn--readmore {
  background-color: #f0f0f0;
  border: none;
  color: var(--text-secondary);
  display: inline-block;
  font-weight: 500;
  @include font-size(12px);
  height: auto !important;
  margin-top: 6px;
  padding: 0.3em 0.6em;
  white-space: nowrap;
  width: auto !important;
  
  &:hover {
    background-color: #e0e0e0;
    color: var(--text-primary);
  }
}

.btn-login,
.c-btn--update {
  align-items: center;
  display: flex;
  height: 34px;
  justify-content: center;
  padding: 0;
  width: 158.38px;
  
  &:disabled {
    background-color: var(--btn-disabled);
    border-color: var(--btn-disabled);
    cursor: not-allowed;
  }
  
  .is-loading {
    display: none;
    
    svg {
      margin: 0;
    }
  }
  
  &.is-active {
    border-color: transparent;
    
    span {
      display: none;
    }
    
    .is-loading {
      align-items: center;
      display: flex;
    }
  }
  
  @media screen and (max-width: 560px) {
    width: 100%;
  }
}

.c-btn--update {
  @media screen and (max-width: 767px) {
    margin-left: auto;
  }
}

.c-btn--collection {
  padding: 0.572em 0.678em;
  white-space: nowrap;
  width: 137px;
  
  svg {
    margin-right: 0.6em;
  }
}

.c-btn--searchform {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  margin-right: 1em;
  outline: none;
  padding: 0;
  
  &:hover {
    background-color: transparent;
  }
  
  @include mq(tablet) {
    display: none;
  }
}

.c-btn--apply {
  @extend .btn-login;
  width: 100%;
}

.btn-virtual-tour {
  background-color: $gold;
  bottom: 0;
  color: var(--text-white);
  display: block;
  padding: 8px 12px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  width: 100%;
  
  &:hover {
    background-color: $gold3;
    color: var(--text-white);
    transition: all 0.2s;
  }
}

.c-btn--view {
  align-items: center;
  background-color: var(--bg);
  border: 1px solid var(--border-color);
  border-radius: 2px;
  color: var(--text-secondary);
  display: flex;
  font-size: 1rem;
  height: 34px;
  justify-content: center;
  margin-left: 0.5em;
  padding: 0;
  width: 34px;
  
  svg {
    path {
      stroke: var(--text-light);
      transition: 0.2s linear 0s;
    }
  }
  
  &:first-child {
    margin-left: 1em;
  }
  
  &:hover {
    background-color: $dark;
    border-color: var(--border-color-active);
    color: var(--text-white);
    
    svg {
      path {
        stroke: var(--bg);
      }
    }
  }
  
  &.is-active {
    background-color: $dark;
    border-color: var(--border-color-active);
    
    svg {
      path {
        stroke: var(--bg);
      }
    }
  }
  
  &:nth-child(2) {
    display: none;
    
    @include mq(tablet) {
      display: flex;
    }
  }
  
  &:last-child {
    display: none;
    
    @include mq(tablet) {
      display: flex;
    }
  }
}

/*

  BTN TOGGLE


*/
button.btn-toggle {
  appearance: none;
  background-color: $white;
  border: 1px solid $medium-gray;
  border-radius: 2px;
  color: $medium-gray;
  cursor: pointer;
  font-size: 14px;
  margin: 8px 0 10px;
  outline: none;
  padding: 0.1em 1.5em;
  transition: all 0.2s;
  
  &:hover {
    background-color: $gold;
    border-color: $gold;
    color: $white;
    transition: all 0.2s;
  }
}

.c-btn--size {
  &:hover {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    color: var(--text-primary);
  }
  
  &.is-active {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    color: var(--text-primary);
  }
}

.fixed-bottom-button {
  align-items: center;
  background: linear-gradient(0deg,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: fixed;
  right: 0;
  width: 340px;
  z-index: 9;
  
  .btn-text {
    align-items: center;
    display: flex;
    justify-content: center;
    
    span {
      align-self: center;
      margin-right: 5px;
    }
  }
  
  .sidebar-hidden & {
    overflow: hidden;
    padding: 15px 0 30px;
    transition: all 0.1s;
    width: 0;
  }
  
  button {
    margin: 0;
    width: 100%;
  }
  
  @include mq($until: desktop) {
    display: block;
    height: auto;
    padding: 10px 0 20px;
    position: relative;
    width: auto;
    
    button {
      width: 100%;
    }
  }
}
