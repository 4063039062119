.save-pdf__modal {
  justify-content: center;
  max-height: 100vh;
  overflow: auto;
  position: relative;
  display: block;
  top: 134px;
  width: 100%;

  .save-pdf__page {
    padding: 40px 0;
    min-height: 100vh;

    header.savd-pdf__header {
      display: flex;
      justify-content: flex-end;
      padding: 10px 15px;
      width: 100%;
      position: fixed;
      top: 70px;
      background-color: $white;
      z-index: 3;
      align-items: center;
      border-bottom: 1px solid $gray-500;

      .checkbox-wrap {
        white-space: nowrap;
        margin: 0 25px 0 0;

        .form-group {
          height: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        label {
          margin-left: 28px;
        }
      }
    }

    .save-pdf__list {
      width: 7.5in;
      margin: 0 auto;
      border: 1px solid transparent;

      &.border {
        border-color: $gray-500;

        .save-pdf__item {
          border-color: $gray-500;

          .save-pdf__details,
          .save-pdf__description {
            border-color: $gray-500;
          }
        }
      }

      .save-pdf__item {
        display: flex;
        width: 100%;
        border-bottom: 1px solid transparent;
        font-size: 14px;
        color: $gray-500;
        font-family: "Arial", Arial, sans-serif;

        .save-pdf__details {
          padding: 10px;
          border-right: 1px solid transparent;
          display: block;
          width: 180px;
          min-width: 180px;

          .detail-row {
            width: 100%;
            padding-bottom: 3px;
            border-bottom: 1px solid #dddddd;

            &:last-child {
              border-bottom: 0;
            }

            div.label {
              width: 100%;
              font-weight: bold;
            }

            div.value {
              width: 100%;
            }

            .item-value {
              display: block;

              &.hide {
                display: none;
              }
            }

            .item-sublocation {
              font-size: 12px;
            }
          }
        }

        .save-pdf__description {
          flex-grow: 1;
          border-right: 1px solid transparent;
          padding: 10px;

          h3 {
            font-size: 16px;
            color: $gray-500;
            margin: 0 0 5px 0;
          }

          .detail-row.item-description {
          }
        }

        .save-pdf__image {
          padding: 10px;
          width: 2.5in;
          min-width: 2.5in;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            max-width: 2in;
            max-height: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    top: 0;
    padding: 0;
    .save-pdf__page {
      padding: 373px 0 0 0;

      header.savd-pdf__header {
        flex-direction: column;

        .checkbox-wrap {
          white-space: nowrap;
          margin: 0 25px 0 0;

          .form-group {
            height: 20px;
            width: 170px;
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          label {
            margin-left: 28px;
          }
        }

        .btn-save-pdf {
          margin-bottom: 15px;
        }
      }

      .spinner {
        margin-top: 20%;
      }
    }
  }
}

// table
table.save-pdf__table {
  width: 750px;
  margin: 0 auto;
  font-size: 8px;
  border: 1px solid $gray-500;
  border-collapse: collapse;

  &.hide-borders {
    border-color: transparent !important;

    td {
      border-color: transparent !important;
    }
  }

  tr.save-pdf__item {
    td.save-pdf__details {
      width: 160px;
      min-width: 160px;
      vertical-align: top;
      border-bottom: 1px solid $gray-500;
      border-right: 1px solid $gray-500;
      padding: 10px;

      > table {
        width: 100%;
        margin: 0;

        tr {
          td {
            width: 100%;
            border-bottom: 1px solid $gray-500;
            padding: 0;

            img.qr-code {
              width: 45px;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }

      .detail-row {
        &:last-child {
        }

        div.label {
          font-weight: bold;
        }

        &.item-qrcode {
          img {
            margin-top: 5px;
            max-width: 90px;
          }
        }
      }
    }

    td.save-pdf__description {
      width: auto;
      vertical-align: top;
      padding: 10px;
      border-bottom: 1px solid $gray-500;
      border-right: 1px solid $gray-500;

      > table {
        width: 100%;
      }

      span.item-title {
        display: block;
        font-size: 10px;
        font-weight: bold;
        white-space: nowrap;
        max-width: 409px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .detail-row.item-description {
      }
    }

    td.save-pdf__image {
      width: 180px;
      min-width: 180px;
      vertical-align: top;
      text-align: center;
      border-bottom: 1px solid $gray-500;
      padding: 10px;

      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
