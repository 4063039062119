#latestPurchasesPage,
#collectionsPage {
  min-height: 100vh;
  padding-bottom: 50px;
}

.slick-slider-wrap {
  padding: 20px 30px 24px;
  background-color: $white;
  border-radius: 2px;
  margin-bottom: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  min-height: 470px;
  position: relative;
  
  .no-results {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    h3 {
      font-family: $spectral;
      font-weight: normal;
      font-size: 24px;
      color: $dark-gray;
    }
  }
  
  .pagination-wrap {
    display: none !important;
  }
  
  .o-list__items,
  .o-list,
  .layout-item-details {
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
  
  header.slider-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 10px;
    
    h3,
    h4 {
      align-self: center;
      margin: 0;
    }
    
    .btn-link {
      align-self: center;
    }
  }
  
  .agile__nav-button {
    width: 48px !important;
    height: 48px !important;
    margin: 0 15px !important;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    border: none !important;
    z-index: 3 !important;
    background-color: rgba(#f0f0f0, 0.5) !important;
    transition: background-color 0.3s !important;
    
    &:before {
      display: none;
    }
  }
  
  .progress-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slick-slider {
    .slick-arrow {
      display: flex !important;
      transform: translateY(-80px);
    }
    
    .slick-track {
      .slick-slide {
        padding: 0 15px;
        
        .c-works__image {
          text-align: center;
          
          div {
            span {
              height: 280px;
              display: block;
              
              img {
                max-height: 100%;
              }
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 599px) {
    min-height: 370px;
    .slick-slider {
      .slick-arrow {
        transform: translateY(-80px);
      }
      
      .slick-track {
        .slick-slide {
          padding: 0 15px;
          
          .c-works__image {
            height: 180px;
            text-align: center;
            
            img {
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}

// scan
#qrScannerWrap {
  .qrcode-stream-wrapper {
    width: 180px;
    height: 180px;
    max-width: 100%;
    margin: 30px auto 0;
    border: 3px solid $forest;
  }
  
  .capture {
    text-align: center;
    width: auto;
    margin: 30px 0;
  }
  
  h3 {
    margin: 10px 0;
  }
  
  .results {
    display: flex;
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    
    ul {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      
      li.item {
        width: 100%;
        background-color: $gold7;
        position: relative;
        padding: 15px 40px;
        border-radius: 10px;
        margin-bottom: 15px;
        overflow: hidden;
        
        > div {
          max-width: 99%;
          white-space: nowrap;
          overflow-x: hidden;
          text-overflow: ellipsis;
          
          span {
            font-weight: bold;
          }
        }
        
        .index {
          align-items: center;
          background-color: $gold4;
          color: $white;
          display: flex;
          flex-direction: column;
          height: 22px;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 22px;
        }
      }
    }
  }
  
  .dropzone {
    width: 500px;
    max-width: 100%;
    margin: 15px auto;
    height: 140px;
    background-color: $gold7;
    border-radius: 10px;
    border: 1px solid $gold4;
  }
  
  .code-wrap {
    display: flex;
    width: 500px;
    max-width: 100%;
    justify-content: space-between;
    margin: 40px auto 0;
    
    img {
      width: 90px;
      height: 90px;
    }
  }
}

#roomScanPage {
  overflow: hidden;
  
  .spinner {
    margin-top: 30px;
    min-height: 180px;
  }
  
  header.section-header {
    text-align: center;
    
    .small {
      font-size: 21px;
    }
  }
  
  .room-items-list {
    padding-bottom: 50px;
    
    .modal-title {
      @include font-size(19px);
      padding-left: 2px;
    }
  }
  
  .room-name {
    margin-left: auto;
  }
  
  section.o-section.c-section--room.js-content {
    padding: 0 !important;
    
    .o-section__wrapper {
      padding: 0;
    }
  }
}

#updateAirtable {
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }
}
