.grid-wrap {
  display: flex;
  flex-wrap: wrap;

  .grid-item {
    @extend .o-list__items;
    margin: 0 0.5em 1em;
    @include mq($from: mobileLandscape) {
      height: 160px !important;
    }
    @include mq($until: mobileLandscape) {
      height: 100px !important;
    }
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

    div.image {
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      cursor: pointer;
      position: relative;
      transition: background 0.2s;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;

      span.overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 0;
        transition: background-color 0.2s;

        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
          transition: background 0.2s;
        }

        span.text {
          color: rgba(255, 255, 255, 1);
          display: flex;
          font-size: 22px;
          font-weight: bold;
          letter-spacing: 0.05em;
          text-align: center;
          text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
          text-transform: uppercase;
          z-index: 1;
          @include mq($until: mobileLandscape) {
            font-size: 19px;
          }
        }

        span.item-count {
          background-color: rgba(0, 0, 0, 0.5);
          width: 100%;
          text-align: center;
          color: rgba(255, 255, 255, 1);
          text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
          margin-top: auto;
          font-size: 13px;
          padding: 10px;
        }
      }
    }

    @include mq(tablet) {
      margin: 0 0.5em 1em;
    }

    &.merchant {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      padding-top: 10px;
      background-color: $white;

      img {
        width: auto;
        height: auto;
        max-width: calc(100% - 40px);
        max-height: 100px;
        margin: auto 30px 0;
      }

      span.text {
        margin-top: auto;
        width: 100%;
        background-color: $gold5;
        color: $white;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        padding: 5px 15px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
      }

      @include mq($until: mobileLandscape) {
        img {
          max-height: 50px;
          margin-bottom: 10px;
        }
      }
    }

    @include mq($until: mobileLandscape) {
      width: 100%;
      margin: 0 0 1em;
    }
  }
}
