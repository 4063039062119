$tl: 0.6s;
.c-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.c-form__items {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  &:last-child {
    margin-top: 1em;
    margin-bottom: 0;

    .c-form--login & {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      @include mq(tablet) {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  label {
    @include font-size(12.8px);
    font-weight: normal;
    color: var(--text-primary);
    margin: 0 0 0.78125em;
  }
}

.c-form__col {
  width: calc(50% - 1em);
  margin-bottom: 1em;

  &:last-child {
    text-align: left;
    margin-bottom: 0;
  }

  @include mq(tablet) {
    margin: 0;
    &:last-child {
      text-align: right;
    }
  }
}

.c-form__input {
  @include font-size(16px);
  background-color: var(--bg);
  border-radius: 2px;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  font-family: $sans;
  height: 40px;
  outline: none;
  padding: 0.625em 1em;

  &::placeholder {
    @include font-size(14px);
    color: var(--text-light);
  }

  &:focus {
    border-color: var(--text-primary);
    z-index: 9;
  }

  &:focus-within {
    border-color: var(--text-primary);
  }

  &.c-form__notes {
    width: 100%;
    resize: none;
    height: 120px;
    margin-bottom: 15px;

    &:focus {
      border-color: $gold4;
    }
    &#notesList {
      border: 0;
      padding: 20px 0;
      line-height: 1em;
      text-align: left;
      height: auto;
    }
  }

  &.item-notes {
    resize: none;
    width: 100%;

    &:focus {
      border-color: $gold4;
    }
  }
}

.c-form__arrow {
  display: none;
  position: absolute;
  right: 1em;
  top: 1.6em;
}

.c-form--secondary-search {
  @extend .c-form--search;
  display: none;

  .c-form__items {
    position: relative;
  }

  .c-form__input {
    width: 100%;
    margin-top: 1em;
    border: 1px solid var(--border-color-active);
    border-radius: 2px;
    cursor: text;
    padding: 0.625em 1em;
    background: var(--bg);
    transition: all 330ms ease-out;

    &:not(:focus) {
      text-indent: unset;
    }

    &:focus {
      width: 100%;
    }
  }

  &.is-open {
    display: flex;
    @include mq(desktop) {
      display: none;
    }
    @include mq(tablet) {
      display: none;
    }
  }
}

.c-form__message {
  @include font-size(14px);
  align-items: center;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  background: var(--bg-secondary);
  border-radius: 2px;
  border: 1px solid var(--border-color);
  font-weight: 500;
  justify-content: flex-start;
  margin: 0 0 1em;
  outline: none;
  padding: 0.7em 1em;

  svg {
    margin-right: 0.535714285714286em;
  }

  span {
    margin-left: 0.3em;
    font-weight: normal;

    a {
      display: none;
    }
  }
}

.login-error {
  align-items: flex-start;
  background-color: #ad1506;
  border-radius: 3px;
  color: $white;
  display: flex;
  font-size: 14px;
  justify-content: flex-start;
  margin: 15px 0;
  max-width: 100%;
  padding: 15px 20px;

  svg {
    margin-right: 6px;
    min-width: 15px;
  }

  span {
    line-height: 15px;

    a {
      display: block;
      width: 100%;
      color: $white;
      text-align: center;
      padding: 10px 0 5px;
    }
  }

  &.visible {
    visibility: visible;
  }
}

.c-form--error {
  background-color: #ad1506;
  border: 1px solid #ad1506;
  color: var(--text-white);
  margin: 0 10px;
  justify-content: center;
  display: flex;
  z-index: 99;

  #cart & {
    position: absolute;
    top: 80px;
    width: auto;
    right: 0;
    margin: 0 15px;

    svg {
      font-size: 16px;
    }

    span {
      margin: 0;
    }

    @media screen and (max-width: 360px) {
      left: 15px;
      flex-wrap: wrap;
      span {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.c-form--success {
  background-color: #6d962a;
  border: 1px solid #6d962a;
  color: var(--text-white);
  margin: 0 10px;
  justify-content: center;
  display: flex;
  z-index: 99;

  #cart & {
    position: absolute;
    top: 80px;
    width: auto;
    right: 0;
    margin: 0 15px;
    justify-content: center;
    max-width: calc(100% - 30px);

    span {
      margin: 0;
    }

    @media screen and (max-width: 360px) {
      left: 15px;
      flex-wrap: wrap;
      span {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.c-form__notvalid {
  @include font-size(12.8px);
  padding-top: 0.3em;
  color: #a34a28;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.c-form--price {
  .c-form__items {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      margin-top: 1em;
      margin-bottom: 0;
    }
  }

  .c-form__input {
    width: calc(50% - 0.75em);
  }

  .c-form__col {
    width: 100%;
  }
}

.c-form__underline {
  font-size: 1.1em;
  color: var(--border-color);
}
