.grid-layout-wrap {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 40px;
  width: auto;
  max-width: 100%;
  padding: 0 15px;

  .t-grid & {
    margin-bottom: 50px;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    min-width: 1px;

    .c-works__image {
      height: auto;
      margin-bottom: auto;

      .is-loaded {
        span {
          display: block;
          text-align: center;

          img {
            max-height: 240px;
            max-width: 100%;
            width: auto;
          }
        }
      }
    }

    .layout-item-details {
      margin-top: auto;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      .layout-item-details-artist {
        .c-link {
          width: calc(100% - 15px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .c-works__details__wrap {
        margin: auto 0 10px;

        .c-works__detail__info {
          margin-top: auto;
        }

        .c-works__location {
          span {
            white-space: nowrap;
          }
        }
      }
    }

    .c-works__details__cta {
      overflow: hidden;
      margin-top: 15px;
      padding-bottom: 0;

      .c-btn {
        width: 32px;
        height: 32px;
        font-size: 13px;

        &.btn-view-details {
          width: auto;
        }
      }
    }
  }

  @include mq($from: ultrawide) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  @include mq($from: superwide, $until: ultrawide) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @include mq($from: wide, $until: superwide) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @include mq($from: desktop, $until: wide) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include mq($from: mobileLandscape, $until: desktop) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: auto;
    padding-top: 35px;
    margin: 0 15px;
  }
  @include mq($until: mobileLandscape) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-top: 35px;
    margin: 0 15px;
  }
}

#favoritesPage {
  .grid-layout-wrap {
    @include mq($from: ultrawide) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include mq($from: desktop, $until: ultrawide) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @include mq($from: tablet, $until: desktop) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include mq($until: tablet) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      padding-top: 35px;
      margin: 0 15px;
    }

    .grid-item {
      background-color: $white;
      padding: 20px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      .c-works__image {
        margin-bottom: auto;
        flex-grow: 1;
      }
    }
  }
}

//mobile: 320px,
//mobileLandscape: 520px,
//tablet: 740px,
//tabletLandscape: 860px,
//desktop: 980px,
//desktopAd: 1110px,
//wide: 1600px,
//superwide: 1920px,
//ultrawide: 2200px,
