.c-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  @include mq(mobileLandscape) {
  }
}

.layout-item {
  min-height: 240px;

  .layout-item-details {
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    cursor: pointer;

    .layout-item-details-artist {
      .c-link {
        width: calc(100% - 15px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .t-list & {
    display: flex;
    width: 100%;
    padding: 20px 0 20px 20px;
    border-bottom: 1px solid var(--border-color);

    .layout-item-details {
      width: 100%;
      display: flex;

      .c-works__title {
        margin-bottom: auto;
      }

      .item-detail-row {
        .item-dimensions {
          width: 100%;
          border-top: 0;
          border-bottom: 1px solid $gray-300;
          padding-bottom: 10px;
        }

        .c-works__location {
          align-self: flex-end;
          width: auto;
        }

        .item-sublocation {
          align-self: flex-end;
          font-size: 12px;
          color: $gray-600;
        }
      }
    }

    .c-works__cta {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-left: 1px solid $gray-300;
      padding: 0 20px;
      margin-left: 20px;
      width: 400px;

      .btn-view-details {
        margin: 0;
        align-self: stretch;
      }

      .btn-row {
        display: flex;
        margin-top: 5px;
        width: 100%;

        .c-btn {
          width: auto;
          align-self: stretch;
          margin: 0;
          padding: 0 10px;
          flex-grow: 1;
          white-space: nowrap;
          display: flex;

          &:last-child {
            margin-left: 5px;
          }

          span.icon {
            align-self: center;
            margin-right: 8px;
          }

          span.btn-text {
            align-self: center;
            display: block;
          }
        }
      }

      @include mq($from: mobileLandscape, $until: desktopAd) {
        width: 300px;
      }
      @include mq($until: tablet) {
        border: 0;
        padding-left: 0;
        margin-left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        .btn-row {
          margin: 10px 0 0 0;
          justify-content: flex-start;
          width: auto;

          .btn {
            align-self: flex-start;
            flex-grow: initial;
          }
        }
      }
    }

    @include mq($until: tablet) {
      flex-direction: column;
      padding: 20px;
      .layout-item-details {
        margin: 0;
        padding-top: 10px;
      }
      .c-works__location {
        width: auto;
      }
    }
  }

  .t-grid & {
    display: flex;
    flex-direction: column;
    height: 100%;

    .c-works__image {
      span {
        display: block;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 240px;
        }
      }
    }
  }

  .c-works__details__wrap {
    margin-top: auto;
  }
}

.masonry-wrap {
  margin: 1rem;
  position: absolute;

  .t-masonry-large & {
    @include mq($from: ultrawide) {
      width: calc((100% / 5) - 2em);
    }
    @include mq($from: superwide, $until: ultrawide) {
      width: calc((100% / 4) - 2em);
    }
    @include mq($from: wide, $until: superwide) {
      width: calc((100% / 3) - 2em);
    }
    @include mq($from: desktop, $until: wide) {
      width: calc((100% / 2) - 2em);
    }
    @include mq($from: desktop, $until: desktopAd) {
      width: calc(100% - 2em);
    }
    @include mq($from: tablet, $until: desktop) {
      width: calc((100% / 2) - 2em);
    }
    @include mq($from: mobile, $until: tablet) {
      width: calc(100% - 2em);
    }
  }

  .t-masonry-small & {
    @include mq($from: ultrawide) {
      width: calc((100% / 6) - 2em);
    }
    @include mq($from: superwide, $until: ultrawide) {
      width: calc((100% / 5) - 2em);
    }
    @include mq($from: wide, $until: superwide) {
      width: calc((100% / 4) - 2em);
    }
    @include mq($from: semiwide, $until: wide) {
      width: calc((100% / 3) - 2em);
    }
    @include mq($from: desktop, $until: semiwide) {
      width: calc((100% / 2) - 2em);
    }
    @include mq($from: mobileLandscape, $until: desktop) {
      width: calc((100% / 2) - 2em);
    }
    @include mq($until: mobileLandscape) {
      width: calc(100% - 2em);
    }
  }

  .c-works__cta {
    display: flex;
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .c-works__photo {
    opacity: 0;
    transition: 0.2s linear 0s;
  }

  .c-works__image:not(.is-loaded) {
    min-height: 4rem;
  }

  @include mq(desktop) {
    .c-works__image {
      transition: all 0.1s ease;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 500px;
      }
    }
  }

  &:hover {
  }
}

.c-view {
  display: flex;
  align-items: center;
}

.c-view__list {
  display: flex;
  align-items: center;
}

.c-view__text {
  @include font-size(14px);
  color: var(--text-primary);
  margin: 0;
  font-weight: normal;
  display: none;
  @include mq(tablet) {
    display: block;
  }
}

.c-table {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @include mq(desktop) {
    overflow-x: unset;
  }
}

.c-table__row {
  display: -webkit-box;
  align-items: center;

  &.c-table__header {
    .c-table__cell {
      font-weight: normal;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: var(--text-secondary);
      background-color: var(--bg) !important;
      padding: 1em 0 1em 2em;
    }
  }

  &:nth-child(2n + 1) {
    .c-table__cell {
      background-color: var(--bg-secondary);
    }
  }
}

.c-table__cell {
  display: flex;
  align-items: center;
  height: 79px;
  @include font-size(16px);
  font-weight: 500;
  width: 240px;
  white-space: nowrap;
  border-bottom: 1px solid var(--border-color);
  padding: 1.25em 0 1.25em 2em;
  @include mq(desktop) {
    &.c-table__cell--collection,
    &.c-table__cell--date,
    &.c-table__cell--dest {
      width: 25.55%;
    }
  }
}

.c-table__cell--num {
  width: 100px;
}

.c-table__cell--status {
  width: 180px;
}

.c-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-dashboard__col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1em;
  flex-wrap: wrap;

  &:last-child {
    justify-content: flex-end;
  }

  @include mq($until: mobileLandscape) {
    width: 100%;
  }
  @include mq(desktop) {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
}

.c-link--dashboard {
  position: relative;
  @include font-size(23px);
  font-weight: 500;
  color: var(--text-primary);
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0 1.160714285714286em 0 0;
  transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;

  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-right: 0.303571428571429em;

    path {
      transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    }
  }

  &:hover {
    color: var(--text-primary);

    svg {
      path {
        fill: var(--heart-black);
        stroke: var(--heart-black);
      }
    }
  }

  &.is-active {
    color: var(--text-primary);

    svg {
      path {
        fill: var(--heart-black);
        stroke: var(--heart-black);
      }
    }
  }
}

.c-dashboard__items {
  margin-right: 0.5em;

  &:last-child {
    margin-right: 0;
  }

  &.c-view {
    margin-right: 0;
  }

  &.c-dropdown {
    display: none;
  }

  @include mq(desktop) {
    &.c-view {
      margin-right: 1.5em;
    }
    &.c-dropdown {
      display: block;
    }
  }
}

.c-section {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5em;
  @include mq(tablet) {
    justify-content: space-between;
  }
}

.c-section__col {
  display: flex;
  align-items: center;
  flex-direction: column;

  .c-section__title {
    margin: 0;
  }

  @include mq(tablet) {
    flex-direction: row;
  }
}

.c-section__title {
  font-family: $spectral;
  @include font-size(36px);
  font-weight: 300;
  margin: 0 0 0.6em;

  .content-block & {
    margin: 0.4em 0;
  }
}

.o-layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include mq(tablet) {
    flex-direction: row;
  }
}

.o-layout__items {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include mq(tablet) {
    width: calc(50% - 1em);
  }
}

.o-layout__items--triple {
  width: 100%;
  @include mq(tablet) {
    width: 33%;
  }
}

.o-section {
  padding: 3em 0;
  @include mq(desktop) {
    padding: 6em 0;
  }
}

.o-section__wrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 5px;
  @include mq(desktop) {
    max-width: calc(1174px - 2em);
  }
  @include mq(wide) {
    max-width: calc(1664px - 2em);
  }

  &.details {
    display: flex;
    justify-content: center;
    padding: 25px 15px 100px;

    &.has-nav {
      padding: 90px 15px 100px;
    }

    @include mq($until: desktopAd) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
}

.o-list {
  display: flex;
  justify-content: space-between;
}

.o-list__wrap {
  flex-flow: row wrap;
}

span.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 0;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s;
  }
}

.no-image-bg {
  background: linear-gradient(0deg, rgb(87, 61, 42), rgb(15, 48, 86));

  &:hover {
    background: linear-gradient(0deg, rgb(97, 71, 52), rgb(25, 58, 96));
  }
}

.o-list__items {
  display: flex;
  align-content: stretch;

  .t-list & {
    a {
      h3 {
        width: 100%;
        text-align: left;
      }
    }
  }

  > div {
    display: flex;
    width: 100%;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      text-align: center;

      &.text {
        font-size: 22px;
      }
    }

    &.btn-virtual-tour {
      display: none;
    }
  }

  &.VueCarousel-slide {
    max-width: calc(100vw - 3em);
  }

  @include mq($until: mobileLandscape) {
    .locations &,
    .categories &,
    .merchants & {
      width: 100%;
      margin: 0 0 10px;
      min-height: 100px;
    }
  }
  @include mq($from: mobileLandscape, $until: tablet) {
    width: calc(50% - 1em);
    max-width: calc(1600px / 2);
    .locations &,
    .categories & {
      height: 200px;
    }
  }
  @include mq(tablet) {
    width: calc(33.33% - 1em);
    max-width: calc(1600px / 3);
    .locations &,
    .categories & {
      height: 200px;
    }
  }
  @include mq(desktop) {
    width: calc(25% - 1em);
    max-width: calc(1600px / 4);
    .locations &,
    .categories & {
      height: 200px;
    }
  }

  .t-list & {
    max-width: 100%;
  }

  .categories &,
  .locations & {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
}

.o-list__triple {
  .o-list__items {
    width: 100%;
    @include mq(mobileLandscape) {
      width: calc(50% - 0.5em);
    }
    @include mq(tablet) {
      width: calc(33.33% - 1em);
    }
  }
}

#locationPage {
  background-size: cover;
  background-repeat: no-repeat;
}

.content-area {
  position: relative;
  overflow: hidden;
  width: 100%;

  .save-pdf-wrap {
    padding: 1.6em 20px 1.25em;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid var(--border-color);

    .c-btn {
      margin-right: 0;
    }
  }

  .sidebar-hidden & {
    width: 100%;
    transition: width 0.1s;
  }

  align-self: flex-end;
  margin-left: auto;

  &.full-height {
    min-height: calc(100vh - 320px);
  }

  @include mq(desktop) {
    width: calc(100% - 354px);
  }
}
